import axios from "axios"
import accessToken from "./jwt-token-access/accessToken"

//pass new generated access token here
// const token = accessToken
const newToken = JSON.parse(localStorage.getItem("authUser") || "{}");
console.log(newToken?.access_token, 'newTokennewTokennewToken', newToken)
const token = "Bearer " + newToken?.access_token;
//apply base url for axios
export const API_URL = "https://api.brakeup.in";


const axiosApi = axios.create({
  baseURL: API_URL,
})

axiosApi.defaults.headers.common["Authorization"] = token
// axiosApi.defaults.headers.common["Access-Control-Allow-Origin"] = "*"


axiosApi.interceptors.response.use(
  response => response,
  error => Promise.reject(error)
)

export async function get(url, config = {}) {
  return await axiosApi.get(url, { ...config }).then(response => response.data)
}

export async function post(url, data, config = {}) {
  console.log(data, 'datadatadatadatadatadatadatadatadata')
  return axiosApi
    .post(url, { ...data }, { ...config })
    .then(response => response.data)
}

export async function put(url, data, config = {}) {
  return axiosApi
    .put(url, { ...data }, { ...config })
    .then(response => response.data)
}

export async function del(url, config = {}) {
  return await axiosApi
    .delete(url, { ...config })
    .then(response => response.data)
}

// axiosApi.interceptors.response.use((response) => {
//   return response
// }, async function (error) {
//   if (error.response.status === 555) {
//     history.push(`/notify/${result.credAuthResult.id}`);

//   }
//   return Promise.reject(error);
// });


