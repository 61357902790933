import React from "react";

import "./sellerlandingstyle.css";
import AliceCarousel from "react-alice-carousel";
import "react-alice-carousel/lib/alice-carousel.css";

import biggestcustomercare from "../../assets/images/landingpage/Biggest Customer care.svg";
import brakeupseller from "../../assets/images/landingpage/Brakeup Seller Services.png";
import dedicatedsellersupport from "../../assets/images/landingpage/Dedicated Seller Support.svg";
import HowtoSellsymbol from "../../assets/images/landingpage/How to Sell on Brakeup Symbol.svg";
import largestautotech from "../../assets/images/landingpage/Largest Auto Tech.svg";
import mosttrustedbrand from "../../assets/images/landingpage/Most Trusted Brand.svg";
import sellerpartnerlogofooter from "../../assets/images/landingpage/Seller Partner Logo Footer.svg";
import sellerpartnerunderline from "../../assets/images/landingpage/Seller Partner Underline.svg";
import sellerpartner from "../../assets/images/landingpage/Seller Partner.svg";
import image1 from "../../assets/images/landingpage/Abhijith M D.png";
import image4 from "../../assets/images/landingpage/Mukesh Aravindh.png";

// import { API_URL } from "helpers/api_helper";

import { HiMenu } from "react-icons/hi";
import { useHistory } from "react-router-dom";
import { Button } from "bootstrap";


const LandingPage = () => {

    const API_URL = "https://api.brakeup.in";

    let history = useHistory()

    return (
        <>
            <div className="sellerpage_landing">
                <div className="part1_landing">
                    <div className="headerpart_landing">
                        <div>
                            <img
                                id="header_logo"
                                className="brakeup_logo_landing"
                                // src="assets/images/Brakeup Seller Services.png"
                                src={brakeupseller}
                            />
                        </div>
                        <div className="hyperlinks_landing  fontManrope">
                            <a href="" className="mhover_landing">
                                Home
                            </a>
                            <a href="" className="mhover_landing">
                                Fee Structure
                            </a>
                            <a href="" className="mhover_landing">
                                FAQ
                            </a>
                            <a onClick={() => history.push("/sellercare")} className="mhover_landing">
                                Seller Care
                            </a>
                        </div>
                            <button onClick={() => history.push("/login")} className="button1_landing fontManrope">Start Selling</button>
                    </div>
                    <div className="signinbody1_landing">
                        <div className="signin_lp_landing">
                            <div id="heading1">Lorem Ipsum Dummy</div>
                            <div className="bodycontent1_landing fontNunito">
                                Es Simplemente El Texto De <br />
                                Relleno De Las Imprentas Y Archivos
                                <br />
                                De Texto. Lorem Ipsum Ha Sido
                                <br />
                                El Texto De Relleno Estándar De
                                <br />
                                Las Industrias Desde El Año 1500 <br />
                                De Las Industrias Desde El Año 1500
                            </div>
                           
                                <button  onClick={() => history.push("/login")} className="signinbutton1_landing fontNunito">Sign In</button>
                            
                        </div>
                        <div className="image1_landing">
                            <lottie-player
                                src={API_URL + "/api/v1/json/Brakeup_Seller_Img1.json"}
                                background="transparent"
                                speed="1"
                                loop
                                autoplay
                            ></lottie-player>
                        </div>
                    </div>
                    <div className="arrow1_landing">
                        <lottie-player
                            src={API_URL + "/api/v1/json/Brakeup_Seller_Downarrow.json"}
                            background="transparent"
                            speed="1"
                            loop
                            autoplay
                        ></lottie-player>
                    </div>
                </div>

                <div className="part2_landing">
                    <span id="heading2">Lorem Ipsum Dummy</span>
                    <div className="signinbody2_landing">
                        <div className="image2_landing">
                            <div className="boxoverlay_landing">
                                <lottie-player
                                    src={API_URL + "/api/v1/json/Brakeup_Seller_Img2.json"}
                                    background="transparent"
                                    speed="0.5"
                                    loop
                                    autoplay
                                ></lottie-player>
                            </div>
                        </div>
                        <div className="createaccount_landing fontNunito">
                            <div className="bodycontent2_landing">
                                {" "}
                                Simplemente El Texto De Relleno De
                                <br /> Las Imprentas Y Archivos De Texto. <br />
                                Lorem Ipsum Ha Sido El Texto De
                                <br />
                                Relleno Estándar De Las Industrias <br />
                                Desde El Año 1500 De Las Industrias <br />
                                Desde El Año 1500
                            </div>
                            <p />
                            <div id="text1">
                                Es Simplemente El Texto De Relleno De Las <br />
                                Imprentas Y Archivos De Texto. <br />
                                Lorem Ipsum Ha Sido El Texto De <br />
                                Relleno Estándar De Las Industrias <br />
                                Desde El Año 1500 De Las Industrias <br />
                                Desde El Año 1500
                            </div>
                            <p />
                            <div className="buttons_landing">
                                
                                    <button onClick={() => history.push("/login")} className="signinbutton2_landing fontNunito">
                                        Sign In
                                    </button>
                                
                                
                                    <button onClick={() => history.push("/register")} className="createaccountbutton_landing fontNunito">
                                        Create Account
                                    </button>
                                
                            </div>
                        </div>
                    </div>
                </div>

                <div className="part3_landing">
                    <span className="heading3_landing fontManrope">
                        How to Become a <b>Seller Partner</b> at Brakeup?
                    </span>

                    <div className="underline_image_landing" >
                        <img src={sellerpartnerunderline} />

                        {/* <img src="assets/images/Seller Partner Underline.svg" /> */}

                    </div>

                    <div className="lotties_landing">
                        <div className="lottiebg_landing">
                            {/* <img className="lot" src="assets/images/Seller Partner.svg" /> */}
                            <img className="lot_landing" src={sellerpartner} />

                            <div className="createlottie_landing">
                                <div className="createA_landing">
                                    <p className="fontheading_landing fontManrope">1.Create Account</p>
                                    <p className="fontcontent_landing fontNunito">
                                        Enter Mobile
                                        <br /> Number/Email and <br />
                                        get an OTP
                                    </p>
                                </div>
                                <div className="lottieA_landing">
                                    <lottie-player
                                        src={
                                            API_URL +
                                            "/api/v1/json/Brakeup_Seller_Create_account.json"
                                        }
                                        background="transparent"
                                        speed="0.7"
                                        loop
                                        autoplay
                                    ></lottie-player>
                                </div>
                            </div>
                            <div className="createlottie_landing">
                                <div className="createB_landing">
                                    <p className="fontheading_landing fontManrope">2.Complete Profile</p>
                                    <p className="fontcontent_landing fontNunito">
                                        Submit and Verify <br />
                                        Seller Name and <br />
                                        Details
                                    </p>
                                </div>
                                <div className="lottieB_landing">
                                    <lottie-player
                                        src={
                                            API_URL +
                                            "/api/v1/json/Brakeup_Seller_Statutory_Details.json"
                                        }
                                        background="transparent"
                                        speed="0.7"
                                        loop
                                        autoplay
                                    ></lottie-player>
                                </div>
                            </div>
                            <div className="createlottie_landing">
                                <div className="createC_landing">
                                    <p className="fontheading_landing fontManrope">3.Statutory Details</p>
                                    <p className="fontcontent_landing fontNunito">
                                        Submit Informations and documents <br />
                                        such as PAN, GST &amp; Bank Account Etc.
                                    </p>
                                </div>
                                <div className="lottieC_landing">
                                    <lottie-player
                                        src={
                                            API_URL +
                                            "/api/v1/json/Brakeup_Seller_Complete_Profile.json"
                                        }
                                        background="transparent"
                                        speed="0.7"
                                        loop
                                        autoplay
                                    ></lottie-player>
                                </div>
                            </div>
                            <div className="createlottie_landing">
                                <div className="createD_landing">
                                    <p className="fontheading_landing fontManrope">4.Start Selling</p>
                                    <p className="fontcontent_landing fontNunito">
                                        Update Your Catalogue <br />
                                        and Start Selling.
                                    </p>
                                </div>
                                <div className="lottieD_landing">
                                    <lottie-player
                                        src={
                                            API_URL + "/api/v1/json/Brakeup_Seller_Start_Selling.json"
                                        }
                                        background="transparent"
                                        speed="0.7"
                                        loop
                                        autoplay
                                    ></lottie-player>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="part4_landing">
                    <span className="heading3_landing fontManrope">How to Sell On Brakeup?</span>
                    <br />
                    <div className="sell_landing">
                        <div className="content1_landing">
                            <div className="searchlist_landing">
                                <div
                                    className="rectanglebg_landing fontManrope"

                                >
                                    <div className="lottiesp_landing">
                                        <lottie-player
                                            src={
                                                API_URL +
                                                "/api/v1/json/Brakeup_Seller_Search_Product_and_List.json"
                                            }
                                            background="transparent"
                                            speed="1"
                                            loop
                                            autoplay
                                        ></lottie-player>
                                    </div>

                                    <div className="sproduct_landing">
                                        Search Product &amp;
                                        <br /> List
                                    </div>
                                </div>
                                <div>
                                    <img
                                        className="sellsymbol_landing"
                                        // src="assets/images/How to Sell on Brakeup Symbol.svg"
                                        src={HowtoSellsymbol}
                                    />
                                </div>
                            </div>
                            <div className="sellingdata_landing fontNunito">
                                Es Simplemente El Texto De
                                <br /> Relleno De Las Imprentas Y <br />
                                Archivos De Texto. Lorem <br />
                                Ipsum Ha Sido El Texto De
                                <br /> Relleno Estándar
                            </div>
                        </div>
                        <div className="content1_landing">
                            <div className="searchlist_landing">
                                <div
                                    className="rectanglebg_landing fontManrope"
                                >
                                    <div className="lottie5_landing">
                                        <lottie-player
                                            src={
                                                API_URL +
                                                "/api/v1/json/Brakeup_Seller_Receive_Order_and_Pack.json"
                                            }
                                            background="transparent"
                                            speed="1"
                                            loop
                                            autoplay
                                        ></lottie-player>
                                    </div>
                                    <div className="sproduct_landing">
                                        Receive Order &amp; <br />
                                        Pack
                                    </div>
                                </div>
                                <div>
                                    <img
                                        className="sellsymbol_landing"
                                        // src="assets/images/How to Sell on Brakeup Symbol.svg"
                                        src={HowtoSellsymbol}
                                    />
                                </div>
                            </div>
                            <div className="sellingdata_landing fontNunito">
                                Es Simplemente El Texto De
                                <br /> Relleno De Las Imprentas Y <br />
                                Archivos De Texto. Lorem <br />
                                Ipsum Ha Sido El Texto De
                                <br /> Relleno Estándar
                            </div>
                        </div>
                        <div className="content1_landing">
                            <div className="searchlist_landing">
                                <div
                                    className="rectanglebg_landing fontManrope"

                                >
                                    <div className="lottie5_landing">
                                        <lottie-player
                                            src={
                                                API_URL +
                                                "/api/v1/json/Brakeup_Seller_Schedule_Pickup_and_Handover.json"
                                            }
                                            background="transparent"
                                            speed="1"
                                            loop
                                            autoplay
                                        ></lottie-player>
                                    </div>
                                    <div className="sproduct_landing">
                                        Schedule Pickup &amp; <br />
                                        Handover
                                    </div>
                                </div>
                                <div>
                                    <img
                                        className="sellsymbol_landing"
                                        // src="assets/images/How to Sell on Brakeup Symbol.svg"
                                        src={HowtoSellsymbol}
                                    />
                                </div>
                            </div>
                            <div className="sellingdata_landing fontNunito">
                                Es Simplemente El Texto De
                                <br /> Relleno De Las Imprentas Y <br />
                                Archivos De Texto. Lorem <br />
                                Ipsum Ha Sido El Texto De
                                <br /> Relleno Estándar
                            </div>
                        </div>
                        <div className="content1last_landing">
                            <div
                                className="rectanglebg_landing fontManrope"
                            // style={{
                            //   backgroundImage: `url('../../assets/images/landingpage/How to Sell on Brakeup Rectangle.png')`,
                            // }}
                            >
                                <div className="lottie5_landing">
                                    <lottie-player
                                        src={
                                            API_URL +
                                            "/api/v1/json/Brakeup_Seller_Complete_Order_and_Get_Paid.json"
                                        }
                                        background="transparent"
                                        speed="1"
                                        loop
                                        autoplay
                                    ></lottie-player>
                                </div>
                                <div className="sproduct_landing">
                                    Complete Order &amp; <br />
                                    Get Paid
                                </div>
                            </div>

                            <div className="sellingdata_landing fontNunito">
                                Es Simplemente El Texto De
                                <br /> Relleno De Las Imprentas Y <br />
                                Archivos De Texto. Lorem <br />
                                Ipsum Ha Sido El Texto De
                                <br /> Relleno Estándar
                            </div>
                        </div>
                    </div>
                </div>


                <div className="part5_landing">
                    <span className="heading3_landing">Success Stories</span>
                    <br />
                    <AliceCarousel
                        autoPlay
                        autoPlayDirection="ltr"
                        infinite="true"
                        autoPlayInterval="4000"
                        disableButtonsControls="false"
                        hide="next-btn"
                    >
                        <div className="successstories1_landing">
                            <div className="sstory_landing">
                                <div className="name12_landing fontManrope">
                                    <img
                                        className="photo1_landing"
                                        // src="assets/images/Abhijith M D.png"
                                        src={image1}
                                    />
                                    <span>
                                        Abhijith M D<br />
                                        <span className="rbr_landing">
                                            RBR Products &amp; Services LLP Kerala
                                        </span>
                                    </span>
                                </div>
                                <div className="story_seller_landing">
                                    <div id="story1">
                                        <p>
                                            <span className="rbrstory_landing fontNunito">
                                                "Es Simplemente El Texto De Relleno De Las Imprentas Y
                                                Archivos De Texto. Lorem Ipsum Ha Sido El Texto De
                                                Relleno Estándar"
                                            </span>
                                        </p>
                                        <br />

                                        <span className="story2_landing fontNunito">
                                            <p>
                                                Es Simplemente El Texto De Relleno De Las Imprentas Y
                                                Archivos De Texto. Lorem Ipsum Ha Sido El Texto De
                                                Relleno Estándar
                                            </p>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="successstories2_landing">
                            <div className="sstory_landing">
                                <div className="name12_landing fontManrope">
                                    <img
                                        className="photo2_landing"
                                        //src="assets/images/Mukesh Aravindh.png"
                                        src={image4}
                                    />
                                    <span>
                                        Mukesh Aravindh
                                        <br />
                                        <span className="rbr_landing">
                                            RBR Products &amp; Services LLP Kerala
                                        </span>
                                    </span>
                                </div>
                                <div className="story_seller_landing">
                                    <div id="story1_landing">
                                        <p>
                                            <span className="rbrstory_landing fontNunito">
                                                "Es Simplemente El Texto De Relleno De Las Imprentas Y
                                                Archivos De Texto. Lorem Ipsum Ha Sido El Texto De
                                                Relleno Estándar"
                                            </span>
                                        </p>
                                        <br />

                                        <span className="story2_landing fontNunito">
                                            <p>
                                                Es Simplemente El Texto De Relleno De Las Imprentas Y
                                                Archivos De Texto. Lorem Ipsum Ha Sido El Texto De
                                                Relleno Estándar
                                            </p>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="successstories1_landing">
                            <div className="sstory_landing">
                                <div className="name12_landing fontManrope">
                                    <img
                                        className="photo1_landing"
                                        //src="assets/images/Abhijith M D.png"
                                        src={image1}
                                    />
                                    <span>
                                        Abhijith M D<br />
                                        <span className="rbr_landing">
                                            RBR Products &amp; Services LLP Kerala
                                        </span>
                                    </span>
                                </div>
                                <div className="story_seller_landing">
                                    <div id="story1_landing">
                                        <p>
                                            <span className="rbrstory_landing fontNunito">
                                                "Es Simplemente El Texto De Relleno De Las Imprentas Y
                                                Archivos De Texto. Lorem Ipsum Ha Sido El Texto De
                                                Relleno Estándar"
                                            </span>
                                        </p>
                                        <br />

                                        <span className="story2_landing fontNunito">
                                            <p>
                                                Es Simplemente El Texto De Relleno De Las Imprentas Y
                                                Archivos De Texto. Lorem Ipsum Ha Sido El Texto De
                                                Relleno Estándar
                                            </p>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="successstories2_landing">
                            <div className="sstory_landing">
                                <div className="name12_landing fontManrope">
                                    <img
                                        className="photo2_landing"
                                        //src="assets/images/Mukesh Aravindh.png"
                                        src={image4}
                                    />
                                    <span>
                                        Mukesh Aravindh
                                        <br />
                                        <span className="rbr_landing">
                                            RBR Products &amp; Services LLP Kerala
                                        </span>
                                    </span>
                                </div>
                                <div className="story_seller_landing">
                                    <div id="story1_landing">
                                        <p>
                                            <span className="rbrstory_landing fontNunito">
                                                "Es Simplemente El Texto De Relleno De Las Imprentas Y
                                                Archivos De Texto. Lorem Ipsum Ha Sido El Texto De
                                                Relleno Estándar"
                                            </span>
                                        </p>
                                        <br />

                                        <span className="story2_landing fontNunito">
                                            <p>
                                                Es Simplemente El Texto De Relleno De Las Imprentas Y
                                                Archivos De Texto. Lorem Ipsum Ha Sido El Texto De
                                                Relleno Estándar
                                            </p>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </AliceCarousel>
                </div>

                <div className="part6_landing">
                    <div className="icons_seller_landing fontManrope">
                        <div className="largest_landing">
                            {" "}
                            <img
                                className="imageproperties_landing"
                                // src="assets/images/Largest Auto Tech.svg"
                                src={largestautotech}
                            />
                            <div className="large_seller_landing">
                                <br />
                                Largest <br />
                                Auto Tech Company
                            </div>
                        </div>
                        <div className="largest_landing">
                            {" "}
                            <img
                                className="imageproperties_landing"
                                // src="assets/images/Biggest Customer care.svg"
                                src={biggestcustomercare}
                            />
                            <div className="large_seller_landing">
                                <br />
                                Biggest
                                <br /> Customer Base
                            </div>
                        </div>
                        <div className="largest_landing">
                            {" "}
                            <img
                                className="imageproperties_landing"
                                // src="assets/images/Dedicated Seller Support.svg"
                                src={dedicatedsellersupport}
                            />
                            <div className="large_seller_landing">
                                <br />
                                Dedicated
                                <br /> Seller Support
                            </div>
                        </div>
                        <div className="largest_landing">
                            {" "}
                            <img
                                className="imageproperties_landing"
                                // src="assets/images/Most Trusted Brand.svg"
                                src={mosttrustedbrand}
                            />
                            <div className="large_seller_landing">
                                <br />
                                Most
                                <br /> Trusted Brand
                            </div>
                        </div>
                    </div>
                </div>
                <div className="footer2_landing footerfont_landing fontNunito">
                    <span className="footerdiv_landing">
                        <div className="header_logo_landing">

                            <img
                                className="footerlogo_landing"
                                onClick={() => window.scrollTo(0, 0)}
                                // src="assets/images/Seller Partner Logo Footer (1).svg"
                                src={sellerpartnerlogofooter}
                            />

                        </div>
                        <div className="about_landing">
                            <a  onClick={() => history.push("/aboutus")} className="aboutcolor_landing  abt_landing">
                                About
                            </a>
                            <br />
                            <a  onClick={() => history.push("/policy")} className="aboutcolor_landing abt_landing">
                                Policy
                            </a>
                            <br />
                            <a  onClick={() => history.push("/contactus")} className="aboutcolor_landing abt_landing">
                                Contact Us
                            </a>
                        </div>
                        <div className="email1_landing abt_landing">
                            Email: seller.care@brakeup.in <br />
                            Call: +91 70 2580 3580
                            <br />
                            {/* Facebook
                            <br /> Instagram
                            <br /> Twitter
                            <br /> Youtube */}
                        </div>
                        <div className="address1_landing fontManrope">
                            <span className="registeredaddress_landing fontManrope">
                                REGISTERED ADDRESS
                            </span>
                            <br />
                            <div className="brakeupinter_landing fontManrope">
                                Brakeup Internet Technologies Private Limited <br />
                                Level 7, Mfar Greenheart, Manyata Tech Park
                                <br /> Bangalore, Karnataka 560045
                                <br /> CIN: U72900KA2020PTC137670
                            </div>
                        </div>
                    </span>
                </div>
                <div className="lastfooter_landing fontNunito">
                    <span onClick={() => window.scrollTo(0, 0)} id="lastdiv1"> © 2020 – 2022 BRAKEUP</span>
                    <span onClick={() => history.push("/sellercare")} id="lastdiv2">HELP CENTER</span>
                </div>
            </div>





            {/* /* <!-- mobileview --> */}

            <div className="sellerpagemobile_landing">
                <div className="top-header_landing">

                    <span>

                        <img
                            className="brakeup_logo1_landing"
                            // src="assets/images/Brakeup Seller Services.png"
                            src={brakeupseller}
                        />
                    </span>

                    {/* <button className="btn border-0" data-bs-toggle="offcanvas" href="#offcanvasExample" role="button" aria-controls="offcanvasExample">
                        <HiMenu fontSize={"1.5rem"} />
                    </button>

                    <div className="offcanvas offcanvas-start" tabIndex="-1" id="offcanvasExample" aria-labelledby="offcanvasExampleLabel">
                        <div className="offcanvas-header">
                            <h5 className="offcanvas-title" id="offcanvasExampleLabel"></h5>
                            <button type="button" className="btn-close text-reset border-0" data-bs-dismiss="offcanvas" aria-label="Close"></button>
                        </div>
                        <div className="offcanvas-body">

                            <ul >
                                <li ><a href="#" className="mobilesidebar">Home</a></li><br></br>
                                <li><a href="#" className="mobilesidebar">Fee Structure</a></li><br></br>
                                <li><a href="#" className="mobilesidebar">FAQ</a></li><br></br>
                                <li><a href="#" className="mobilesidebar">Seller Care</a></li><br></br>
                                <li><a href="#" className="mobilesidebar">Start Selling</a></li>
                            </ul>

                        </div> */}

                <button className="btn border-0"   data-bs-toggle="offcanvas" href="#offcanvasExample" role="button" aria-controls="offcanvasExample">
                        <HiMenu fontSize={"1.5rem"} />
                    </button>

                    <div className="offcanvas offcanvas-start" tabIndex="-1" id="offcanvasExample" aria-labelledby="offcanvasExampleLabel">
                        <div className="offcanvas-header">
                            <h5 className="offcanvas-title" id="offcanvasExampleLabel"></h5>
                            <button  type="button" className="btn-close text-reset border-0" data-bs-dismiss="offcanvas" aria-label="Close"></button>
                        </div>
                        <div className="offcanvas-body">

                            {/* <p className="mb-0"> */}
                                <ul >
                                    <li className="mobilesidebar_landing">Home</li><br></br>
                                    <li className="mobilesidebar_landing">Fee Structure</li><br></br>
                                    <li className="mobilesidebar_landing">FAQ</li><br></br>
                                    <li onClick={() => history.push("/sellercare")} className="mobilesidebar_landing">Seller Care</li><br></br>
                                    <li onClick={() => history.push("/login")} className="mobilesidebar_landing">Start Selling</li>
                                </ul>
                            {/* </p>  */}

                        </div>
                    </div>

                </div>
                <div className="part1_landing">
                    <div className="signinbody11_landing">
                        <div className="image11_landing">
                            <lottie-player
                                src={API_URL + "/api/v1/json/Brakeup_Seller_Img1.json"}
                                background="transparent"
                                speed="1"
                                loop
                                autoplay
                            ></lottie-player>
                        </div>
                        <div className="signin_lp_landing fontManrope">
                            <div id="heading1">Lorem Ipsum Dummy</div>
                            <div className="bodycontent1_landing fontNunito">
                                Es Simplemente El Texto De Relleno De Las
                                <br />
                                Imprentas Y Archivos De Texto. Lorem Ipsum Ha
                                <br /> Sido El Texto De Relleno Estándar De Las
                                <br /> Industrias Desde El Año 1500 <br />
                                De Las Industrias Desde El Año 1500
                                <div>
                                    <button  onClick={() => history.push("/login")}  className="signinbutton11_landing fontNunito">
                                        Start Selling
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className="arrow11_landing">
                            <lottie-player
                                src={API_URL + "/api/v1/json/Brakeup_Seller_Downarrow.json"}
                                background="transparent"
                                speed="1"
                                loop
                                autoplay
                            ></lottie-player>
                        </div>
                    </div>
                </div>
                <div className="part2_landing">
                    <div className="signinbody22_landing">
                        <div className="image2_landing">
                            <lottie-player
                                src={API_URL + "/api/v1/json/Brakeup_Seller_Img2.json"}
                                background="transparent"
                                speed="1"
                                loop
                                autoplay
                            ></lottie-player>
                        </div>
                        <span id="heading2">Lorem Ipsum Dummy</span>
                        <br />
                        <div className="createaccount_landing fontNunito">
                            <div className="bodycontent2_landing">
                                {" "}
                                Simplemente El Texto De Relleno De
                                <br /> Las Imprentas Y Archivos De Texto. <br />
                                Lorem Ipsum Ha Sido El Texto De
                                <br />
                                Relleno Estándar De Las Industrias
                                <br />
                                Desde El Año 1500
                                <br /> De Las Industrias Desde El Año 1500
                            </div>
                            <div id="text1">
                                Es Simplemente El Texto De Relleno De Las Imprentas Y <br />
                                Archivos De Texto. Lorem Ipsum Ha Sido El Texto De
                                <br />
                                Relleno Estándar De Las Industrias Desde El Año 1500 <br />
                                De Las Industrias Desde El Año 1500
                            </div>
                            <div className="buttons1_landing">
                                <button onClick={() => history.push("/login")}  className="signinbutton2_landing fontNunito">Sign In</button>
                                <button  onClick={() => history.push("/register")}  className="createaccountbutton_landing fontNunito">
                                    Create Account
                                </button>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="part3mobile_landing">
                    <span className="heading3_landing fontManrope">
                        How to Become a <b>Seller Partner</b> at Brakeup?
                    </span>
                    <br />
                    <div className="underline_image_landing">
                        <img src={sellerpartnerunderline} />
                    </div>

                    <div className="lotties_landing">
                        <div className="lottiebg_landing">
                            {/* <img className="lot1" src="assets/images/Seller Partner.svg" /> */}
                            <img className="lot1_landing" src={sellerpartner} />

                            <div className="createlottie_landing">
                                <div className="createA1_landing">
                                    <p className="fontheading_landing fontManrope">1.Create Account</p>
                                    <p className="fontcontent_landing fontNunito">
                                        Enter Mobile
                                        <br /> Number/Email and <br />
                                        get an OTP
                                    </p>
                                </div>
                                <div className="lottieA1_landing">
                                    <lottie-player
                                        src={
                                            API_URL +
                                            "/api/v1/json/Brakeup_Seller_Create_account.json"
                                        }
                                        background="transparent"
                                        speed="1"
                                        loop
                                        autoplay
                                    ></lottie-player>
                                </div>
                            </div>
                            <div className="createlottie_landing">
                                <div className="createB1_landing">
                                    <p className="fontheading_landing fontManrope">2.Complete Profile</p>
                                    <p className="fontcontent_landing fontNunito">
                                        Submit and Verify <br />
                                        Seller Name and <br />
                                        Details
                                    </p>
                                </div>
                                <div className="lottieB1_landing">
                                    <lottie-player
                                        src={
                                            API_URL +
                                            "/api/v1/json/Brakeup_Seller_Statutory_Details.json"
                                        }
                                        background="transparent"
                                        speed="1"
                                        loop
                                        autoplay
                                    ></lottie-player>
                                </div>
                            </div>
                            <div className="createlottie_landing">
                                <div className="createC1_landing">
                                    <p className="fontheading_landing fontManrope">3.Statutory Details</p>
                                    <p className="fontcontent_landing fontNunito">
                                        Submit Informations and documents <br />
                                        such as PAN, GST &amp; Bank Account Etc.
                                    </p>
                                </div>
                                <div className="lottieC1_landing">
                                    <lottie-player
                                        src={
                                            API_URL +
                                            "/api/v1/json/Brakeup_Seller_Complete_Profile.json"
                                        }
                                        background="transparent"
                                        speed="1"
                                        loop
                                        autoplay
                                    ></lottie-player>
                                </div>
                            </div>
                            <div className="createlottie_landing">
                                <div className="createD1_landing">
                                    <p className="fontheading_landing fontManrope">4.Start Selling</p>
                                    <p className="fontcontent_landing fontNunito">
                                        Update Your Catalogue <br />
                                        and Start Selling.
                                    </p>
                                </div>
                                <div className="lottieD1_landing">
                                    <lottie-player
                                        src={
                                            API_URL + "/api/v1/json/Brakeup_Seller_Start_Selling.json"
                                        }
                                        background="transparent"
                                        speed="1"
                                        loop
                                        autoplay
                                    ></lottie-player>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="part4_landing">
                    <div className="heading3_landing fontManrope">How to Sell On Brakeup?</div>
                    <br />
                    <div className="sell1_landing">
                        <div className="content11_landing">
                            <span className="searchlist1_landing">
                                <div
                                    className="rectanglebg1_landing fontManrope"
                                // style={{
                                //   backgroundImage: `url('assets/images/How to Sell on Brakeup Rectangle.png')`,
                                // }}
                                >
                                    <div className="lottiesp1_landing">
                                        <lottie-player
                                            src={
                                                API_URL +
                                                "/api/v1/json/Brakeup_Seller_Search_Product_and_List.json"
                                            }
                                            background="transparent"
                                            speed="1"
                                            loop
                                            autoplay
                                        ></lottie-player>
                                    </div>

                                    <div className="sproduct_landing">List</div>
                                </div>
                                <div>
                                    <img
                                        className="sellsymbol1_landing"
                                        // src="assets/images/How to Sell on Brakeup Symbol.svg"
                                        src={HowtoSellsymbol}
                                    />
                                </div>
                            </span>
                            <div className="sellingdata_landing fontNunito">
                                Es Simplemente El Texto De
                                <br /> Relleno De Las Imprentas Y <br />
                                Archivos De Texto. Lorem <br />
                                Ipsum Ha Sido El Texto De
                                <br /> Relleno Estándar
                            </div>
                        </div>
                        <div className="content11_landing">
                            <span className="searchlist1_landing">
                                <div
                                    className="rectanglebg1_landing fontManrope"
                                >
                                    <div className="lottie5_landing">
                                        <lottie-player
                                            src={
                                                API_URL +
                                                "/api/v1/json/Brakeup_Seller_Receive_Order_and_Pack.json"
                                            }
                                            background="transparent"
                                            speed="1"
                                            loop
                                            autoplay
                                        ></lottie-player>
                                    </div>
                                    <div className="sproduct_landing">Pack</div>
                                </div>
                                <div>
                                    <img
                                        className="sellsymbol1_landing"
                                        src={HowtoSellsymbol}
                                    />
                                </div>
                            </span>
                            <div className="sellingdata_landing fontNunito">
                                Es Simplemente El Texto De
                                <br /> Relleno De Las Imprentas Y <br />
                                Archivos De Texto. Lorem <br />
                                Ipsum Ha Sido El Texto De
                                <br /> Relleno Estándar
                            </div>
                        </div>
                        <div className="content11_landing">
                            <span className="searchlist1_landing">
                                <div
                                    className="rectanglebg1_landing fontManrope"
                                // style={{
                                //   backgroundImage: `url('assets/images/How to Sell on Brakeup Rectangle.png')`,
                                // }}
                                >
                                    <div className="lottie5_landing">
                                        <lottie-player
                                            src={
                                                API_URL +
                                                "/api/v1/json/Brakeup_Seller_Schedule_Pickup_and_Handover.json"
                                            }
                                            background="transparent"
                                            speed="1"
                                            loop
                                            autoplay
                                        ></lottie-player>
                                    </div>
                                    <div className="sproduct_landing">Handover</div>
                                </div>
                                <div>
                                    <img
                                        className="sellsymbol1_landing"
                                        // src="assets/images/How to Sell on Brakeup Symbol.svg"
                                        src={HowtoSellsymbol}
                                    />
                                </div>
                            </span>
                            <div className="sellingdata_landing fontNunito">
                                Es Simplemente El Texto De
                                <br /> Relleno De Las Imprentas Y <br />
                                Archivos De Texto. Lorem <br />
                                Ipsum Ha Sido El Texto De
                                <br /> Relleno Estándar
                            </div>
                        </div>
                        <div className="content11_landing">
                            <span className="searchlist1_landing">
                                <div
                                    className="rectanglebg1_landing fontManrope"
                                // style={{
                                //   backgroundImage: `url('assets/images/How to Sell on Brakeup Rectangle.png')`,
                                // }}
                                >
                                    <div className="lottie5_landing">
                                        <lottie-player
                                            src={
                                                API_URL +
                                                "/api/v1/json/Brakeup_Seller_Complete_Order_and_Get_Paid.json"
                                            }
                                            background="transparent"
                                            speed="1"
                                            loop
                                            autoplay
                                        ></lottie-player>
                                    </div>
                                    <div className="sproduct_landing">Get Paid</div>
                                </div>
                            </span>
                            <div className="sellingdata_landing fontNunito">
                                Es Simplemente El Texto De
                                <br /> Relleno De Las Imprentas Y <br />
                                Archivos De Texto. Lorem <br />
                                Ipsum Ha Sido El Texto De
                                <br /> Relleno Estándar
                            </div>
                        </div>
                    </div>
                </div>

                {/* <!-- mobile carosel --> */}


                <div className="part5_landing">
                    <span className="heading3_landing">Success Stories</span>
                    <br />
                    <div>
                        <AliceCarousel
                            autoPlay
                            autoPlayDirection="ltr"
                            infinite="true"
                            autoPlayInterval="4000"
                            disableButtonsControls="false"
                            hide="next-btn"
                        >
                            <div className="successstoriesm1_landing">
                                <div className="sstory_landing">
                                    <div className="name12_landing fontManrope">
                                        <img
                                            className="photo1_landing"
                                            // src="assets/images/Abhijith M D.png"
                                            src={image1}
                                        />
                                        <span>
                                            Abhijith M D<br />
                                            <span className="rbr_landing">
                                                RBR Products &amp; Services LLP Kerala
                                            </span>
                                        </span>
                                    </div>
                                    <div className="story_seller_landing">
                                        <div id="story1_landing">
                                            <p>
                                                <span className="rbrstory_landing fontNunito">
                                                    "Es Simplemente El Texto De Relleno De Las Imprentas Y
                                                    Archivos De Texto. Lorem Ipsum Ha Sido El Texto De
                                                    Relleno Estándar"
                                                </span>
                                            </p>
                                            <br />

                                            <span className="story2_landing fontNunito">
                                                <p>
                                                    Es Simplemente El Texto De Relleno De Las Imprentas Y
                                                    Archivos De Texto. Lorem Ipsum Ha Sido El Texto De
                                                    Relleno Estándar
                                                </p>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="successstoriesm2_landing">
                                <div className="sstory_landing">
                                    <div className="name12_landing fontManrope">
                                        <img
                                            className="photo2_landing"
                                            //src="assets/images/Mukesh Aravindh.png"
                                            src={image4}
                                        />
                                        <span>
                                            Mukesh Aravindh
                                            <br />
                                            <span className="rbr_landing">
                                                RBR Products &amp; Services LLP Kerala
                                            </span>
                                        </span>
                                    </div>
                                    <div className="story_seller_landing">
                                        <div id="story1_landing">
                                            <p>
                                                <span className="rbrstory_landing fontNunito">
                                                    "Es Simplemente El Texto De Relleno De Las Imprentas Y
                                                    Archivos De Texto. Lorem Ipsum Ha Sido El Texto De
                                                    Relleno Estándar"
                                                </span>
                                            </p>
                                            <br />

                                            <span className="story2_landing fontNunito">
                                                <p>
                                                    Es Simplemente El Texto De Relleno De Las Imprentas Y
                                                    Archivos De Texto. Lorem Ipsum Ha Sido El Texto De
                                                    Relleno Estándar
                                                </p>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="successstoriesm1_landing">
                                <div className="sstory_landing">
                                    <div className="name12_landing fontManrope">
                                        <img
                                            className="photo1_landing"
                                            // src="assets/images/Abhijith M D.png"
                                            src={image1}
                                        />
                                        <span>
                                            Abhijith M D<br />
                                            <span className="rbr_landing">
                                                RBR Products &amp; Services LLP Kerala
                                            </span>
                                        </span>
                                    </div>
                                    <div className="story_seller_landing">
                                        <div id="story1_landing">
                                            <p>
                                                <span className="rbrstory_landing fontNunito">
                                                    "Es Simplemente El Texto De Relleno De Las Imprentas Y
                                                    Archivos De Texto. Lorem Ipsum Ha Sido El Texto De
                                                    Relleno Estándar"
                                                </span>
                                            </p>
                                            <br />

                                            <span className="story2_landing fontNunito">
                                                <p>
                                                    Es Simplemente El Texto De Relleno De Las Imprentas Y
                                                    Archivos De Texto. Lorem Ipsum Ha Sido El Texto De
                                                    Relleno Estándar
                                                </p>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="successstoriesm2_landing">
                                <div className="sstory_landing">
                                    <div className="name12_landing fontManrope">
                                        <img
                                            className="photo2_landing"
                                            //src="assets/images/Mukesh Aravindh.png"
                                            src={image4}
                                        />
                                        <span>
                                            Mukesh Aravindh
                                            <br />
                                            <span className="rbr_landing">
                                                RBR Products &amp; Services LLP Kerala
                                            </span>
                                        </span>
                                    </div>
                                    <div className="story_seller_landing">
                                        <div id="story1_landing">
                                            <p>
                                                <span className="rbrstory_landing fontNunito">
                                                    "Es Simplemente El Texto De Relleno De Las Imprentas Y
                                                    Archivos De Texto. Lorem Ipsum Ha Sido El Texto De
                                                    Relleno Estándar"
                                                </span>
                                            </p>
                                            <br />

                                            <span className="story2_landing fontNunito">
                                                <p>
                                                    Es Simplemente El Texto De Relleno De Las Imprentas Y
                                                    Archivos De Texto. Lorem Ipsum Ha Sido El Texto De
                                                    Relleno Estándar
                                                </p>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </AliceCarousel>
                    </div>
                </div>

                <div className="part6_landing fontManrope">
                    <div className="icons_seller_landing fontManrope">
                        <div className="largest_landing">
                            {" "}
                            <img
                                className="imageproperties_landing"
                                // src="assets/images/Largest Auto Tech.svg"
                                src={largestautotech}
                            />
                            <div className="large_seller_landing">
                                <br />
                                Largest <br />
                                Auto Tech Company
                            </div>
                        </div>
                        <div className="largest_landing">
                            {" "}
                            <img
                                className="imageproperties_landing"
                                // src="assets/images/Biggest Customer care.svg"
                                src={biggestcustomercare}
                            />
                            <div className="large_seller_landing">
                                <br />
                                Biggest
                                <br /> Customer Base
                            </div>
                        </div>
                        <div className="largest_landing">
                            {" "}
                            <img
                                className="imageproperties_landing"
                                // src="assets/images/Dedicated Seller Support.svg"
                                src={dedicatedsellersupport}
                            />
                            <div className="large_seller_landing">
                                <br />
                                Dedicated
                                <br /> Seller Support
                            </div>
                        </div>
                        <div className="largest_landing">
                            {" "}
                            <img
                                className="imageproperties_landing"
                                // src="assets/images/Most Trusted Brand.svg"
                                src={mosttrustedbrand}
                            />
                            <div className="large_seller_landing">
                                <br />
                                Most
                                <br /> Trusted Brand
                            </div>
                        </div>
                    </div>
                </div>
                <div className="footer2_landing footerfont_landing fontNunito">
                    <span className="footer1_landing">
                        <div className="about1_landing">
                            <span onClick={() => history.push("/aboutus")} className="aboutcolor_landing  abt_landing">
                                About
                            </span>
                            <span  onClick={() => history.push("/policy")} className="aboutcolor_landing  abt_landing">
                                Policy
                            </span>
                            <span  onClick={() => history.push("/contactus")} className="aboutcolor_landing  abt_landing">
                                Contact Us
                            </span>
                        </div>
                    </span>
                </div>
                <div className="lastfootermobile_landing fontNunito">
                    <span onClick={() => window.scrollTo(0, 0)} id="lastdiv1"> © 2020 – 2022 BRAKEUP</span>
                    <span onClick={() => history.push("/sellercare")} id="lastdiv2">HELP CENTER</span>
                </div>
            </div>
        </>
    );
};

export default LandingPage;
