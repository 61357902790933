import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
import {
  Container,
  Row,
  Col,
  Button,
  Card,
  CardBody,
  CardSubtitle,
  Input,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Media,
  Table,
  CardTitle,
  CardText,
  Badge
} from "reactstrap"
import TopCard from './topCards';
import Menu from './Menus'
import { Link } from "react-router-dom"
import { useHistory} from 'react-router-dom'
import moment from "moment";

//import action



//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"

//i18n
import { withTranslation } from "react-i18next"

//redux
import { useSelector, useDispatch } from "react-redux"
import { get } from "../../helpers/api_helper";

const Dashboard = props => {
  const [modal, setmodal] = useState(false);
  const [counts, setCounts] = useState({});
  const [subscribemodal, setSubscribemodal] = useState(false);
  const [amount, setAmount] = useState({});
  const history = useHistory()

  const { chartsData } = useSelector(state => ({
    chartsData: state.Dashboard.chartsData
  }))



  useEffect(() => {
    const token = JSON.parse(localStorage.getItem("authUser"));
    get(`/api/v1/seller/dashboard/?user_id=${token.id}`).then(res=>{
      setCounts(res);
    }).catch(err=>{
      setCounts({})
    })
    get(`/api/v1/accounts/total_despatch_amount/`).then(res=>{
      setAmount(res.data);
    }).catch(err=>{
      setAmount({});
    })
  }, [])

  const [periodData, setPeriodData] = useState([])
  const [periodType, setPeriodType] = useState("yearly")

  useEffect(() => {
    setPeriodData(chartsData)
  }, [chartsData])



  const dispatch = useDispatch()
  useEffect(() => {
    // dispatch(onGetChartsData("yearly"))
  }, [dispatch]);

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Dashboard | BrakeUp</title>
        </MetaTags>
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs
            title={props.t("Dashboards")}
            breadcrumbItem={props.t("Dashboard")}
          />

         
          <br/>
          
          <br/>
          <Row>
            <Col xl="12">
              <Row>
                <Menu
                onclick={()=>history.push('/orders')}
                  color={'success'}
                  title={'New Orders'}
                  icon="mdi-order-bool-descending"
                  count={counts.seller_placed_orders}
                />
                <Menu
                  onclick={()=>history.push('/seller-details')}
                  color={'warning'}
                  title={'Products'}
                  icon="mdi-shopping"
                />
                <Menu
                  onclick={()=>history.push('/payments')}
                  color={'danger'}
                  title={'Payments'}
                  icon="mdi-account"
                />
              </Row>
            </Col>
          </Row>

          <Row>
            <Col xl="12">
              <Row>
              <Col xl="3">
                <Card style={{height:"150px"}}>
                  <CardBody>
                    <CardTitle tag="h5">{counts.seller_open_orders}</CardTitle>
                    <CardSubtitle tag="h6" className="mb-2 text-muted">Open Orders</CardSubtitle>
                  </CardBody>
                </Card>
              </Col>
              <Col xl="3">
                <Card style={{height:"150px"}}>
                  <CardBody>
                    <CardTitle tag="h5">{counts.countorders_month}</CardTitle>
                    <CardSubtitle tag="h6" className="mb-2 text-muted">Closed Orders of the month</CardSubtitle>
                  </CardBody>
                </Card>
              </Col>
              <Col xl="3">
                <Card style={{height:"150px"}}>
                  <CardBody>
                    <CardTitle tag="h5">{counts.lowstock_products}</CardTitle>
                    <CardSubtitle tag="h6" className="mb-2 text-muted">Low stock Product</CardSubtitle>
                  </CardBody>
                </Card>
              </Col>
              
              </Row>
                
            </Col>
          </Row>
          
        </Container>
      </div>

 
    </React.Fragment>
  )
}

Dashboard.propTypes = {
  t: PropTypes.any,
  chartsData: PropTypes.any,
}

export default withTranslation()(Dashboard)
