import React, { useEffect } from "react"
import { Row, Col, Card, CardBody, CardTitle, CardSubtitle } from "reactstrap"
import MetaTags from 'react-meta-tags'

// datatable related plugins
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, {
  PaginationProvider, PaginationListStandalone,
  SizePerPageDropdownStandalone
} from 'react-bootstrap-table2-paginator';

import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';

//Import Breadcrumb
// import Breadcrumbs from "../../components/Common/Breadcrumb"
import "../Tables/datatables.scss";
import { get } from "../../helpers/api_helper";
import moment from 'moment';
import { useState } from "react";
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { DateRange } from 'react-date-range';

const InvoiceTables = () => {

  const formatWithIcon = (e) => {
    return (
      <i className="mdi mdi-briefcase-download ms-auto"
        onClick={() => window.open(e)}></i>
    )
  }
  const columns = [{
    dataField: 'no',
    text: 'Sl No',
    sort: true,
  }, {
    dataField: 'date',
    text: 'Date',
    sort: true
  }, {
    dataField: 'order_id',
    text: 'Order No',
    sort: true
  },
  {
    dataField: 'buyer_name',
    text: 'Buyer',
    sort: true
  },
  {
    dataField: 'invoice_no',
    text: 'Invoice',
    sort: true
  },
  {
    dataField: 'price',
    text: 'Amount',
    sort: true
  },
  {
    dataField: 'view',
    text: 'View',
    formatter: formatWithIcon,
  },];


  const [state, setState] = useState([
    {
      startDate: new Date(),
      endDate: null,
      key: 'selection'
    }
  ]);

  const [productData, setProductData] = useState([])
  const [despatchAmount, setDespatchAmount] = useState(0)
  const [totalOrders, setTotalOrders] = useState(0)
  const getData = (date_from, date_to) => {
    const token = JSON.parse(localStorage.getItem("authUser"));
    get(`/api/v1/accounts/invoices/?datefrom=${date_from}&dateto=${date_to}&user_id=${token.id}`).then(res => {
      let invoices = [];
      res.data && res.data.invoice_details.map((items, index) => {
        invoices.push({
          "no": (index + 1),
          "date": moment(items.approved_date["$date"]).format("DD-MM-YYYY"),
          "order_id": items.order_id,
          "price": items.selling_price,
          "buyer_name": items.buyer_name,
          "invoice_no": items.invoice_no,
          "view": items.invoice_url

        })

      })
      setProductData(invoices);
      setTotalOrders(res.data.total_orders);
      setDespatchAmount(res.data.total_despatch_amount);
    }).catch(err => {
      setProductData([])
    })
  }
  useEffect(() => {
    getData(moment(new Date()).format("YYYY-MM-DD"),
      moment(new Date()).format("YYYY-MM-DD"))
  }, [])

  const defaultSorted = [{
    dataField: 'id',
    order: 'asc'
  }];

  const pageOptions = {
    sizePerPage: 10,
    totalSize: productData.length, // replace later with size(customers),
    custom: true,
  }

  // Custom Pagination Toggle
  const sizePerPageList = [
    { text: '5', value: 5 },
    { text: '10', value: 10 },
    { text: '15', value: 15 },
    { text: '20', value: 20 },
    { text: '25', value: 25 },
    { text: 'All', value: (productData).length }];


  // Select All Button operation
  const selectRow = {
    mode: 'checkbox'
  }

  const { SearchBar } = Search;

  const rowEvents = {
    onClick: (e, row, rowIndex) => {
      console.log(row, rowIndex, e)
    }
  };
  const handleSelect = (e) => {
    console.log(e, 'HREEE')
  }
  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Data Tables |  BrakeUp</title>
        </MetaTags>
        <div className="container-fluid">
          {/* <Breadcrumbs title="Tables" breadcrumbItem="Data Tables" /> */}

          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <CardTitle className="h4">INVOICES </CardTitle>

                  <div>
                    <PaginationProvider
                      pagination={paginationFactory(pageOptions)}
                      keyField='id'
                      columns={columns}
                      data={productData}
                    >
                      {({ paginationProps, paginationTableProps }) => (
                        <ToolkitProvider
                          keyField='id'
                          columns={columns}
                          data={productData}
                          search
                        >
                          {toolkitProps => (
                            <React.Fragment>

                              <Row className="mb-2">
                                <Col md="4">
                                  <div className="search-box me-2 mb-2 d-inline-block">
                                    <div className="position-relative">
                                      <SearchBar
                                        {...toolkitProps.searchProps}
                                      />
                                      <i className="bx bx-search-alt search-icon" />
                                    </div>
                                  </div>
                                </Col>
                                <Col md="4"></Col>
                                <Col md="4">
                                  <DateRange
                                    editableDateInputs={true}
                                    onChange={item => {
                                      setState([item.selection]);

                                      getData(moment(item.selection.startDate).format("YYYY-MM-DD"),
                                        moment(item.selection.endDate).format("YYYY-MM-DD"))
                                    }}
                                    moveRangeOnFirstSelection={false}
                                    ranges={state}
                                  // showDateDisplay={false}
                                  />
                                </Col>
                              </Row>

                              <Row>
                                <Col xl="12">
                                  <div className="table-responsive">
                                    <BootstrapTable
                                      keyField={"id"}
                                      responsive
                                      bordered={false}
                                      striped={false}
                                      defaultSorted={defaultSorted}
                                      // rowEvents={ rowEvents }
                                      // selectRow={selectRow}
                                      classes={
                                        "table align-middle table-nowrap"
                                      }
                                      headerWrapperClasses={"thead-light"}
                                      {...toolkitProps.baseProps}
                                      {...paginationTableProps}
                                    />

                                  </div>
                                </Col>
                              </Row>

                              <Row className="align-items-md-center mt-30">
                                <Col className="inner-custom-pagination d-flex">
                                  <div className="d-inline">
                                    <SizePerPageDropdownStandalone
                                      {...paginationProps}
                                    />
                                  </div>
                                  <div className="text-md-right ms-auto">
                                    <PaginationListStandalone
                                      {...paginationProps}
                                    />
                                  </div>
                                </Col>
                              </Row>
                            </React.Fragment>
                          )
                          }
                        </ToolkitProvider>
                      )
                      }</PaginationProvider>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col xl="4">
              <Card style={{ height: "150px" }}>
                <CardBody>
                  <CardTitle tag="h5">Total Despatch amount: {despatchAmount}</CardTitle>
                  <CardTitle tag="h5">Total Number of Orders: {totalOrders}</CardTitle>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  )
}

export default InvoiceTables
