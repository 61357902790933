import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
import { Row, Col, CardBody, Card, Alert, Container, Label, Button } from "reactstrap"

// availity-reactstrap-validation
import { AvForm, AvField } from "availity-reactstrap-validation"

// action
import { registerUser, apiError } from "../../store/actions"

//redux
import { useSelector, useDispatch } from "react-redux"

import { Link } from "react-router-dom"

// import images
import profileImg from "../../assets/images/profile-img.png"
import logoImg from "../../assets/images/logo.png"
import { useForm, Controller, useFieldArray } from "react-hook-form";
import { CustomInput } from "../Ecommerce/customInputs";
import { post, get } from "../../helpers/api_helper";
import { success, error } from "../../service/alerts";
import { Loader } from "../../helpers/loader";
import logo from "assets/images/logo.png"
import { useHistory } from "react-router-dom";


const Register = props => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [type, setType] = useState("generateOtp"); //generateOtp, validateOtp, register
  const [mobileNumber, setMobileNumber] = useState(null);

  const validatePhone = (number) => {
    // Phone number validations
    var re = /^[1-9]\d{9}$|^[1-9]\d{9}$/g;
    return re.test(number);
  };
  const GenerateOtp = () => {
    const { handleSubmit, errors, control } = useForm({
      mode: 'onChange'
    });
    const [loader, setLoader] = useState(false);
    const [errorMsg, setErrorMsg] = useState(null);
    const onSubmit = (values) => {
      setLoader(true);
      post("/api/v1/user/mobile_register/", values).then(res => {
        setMobileNumber(values.mobile);
        setLoader(false);
        setErrorMsg(null);
        setType("validateOtp");
        
      }).catch(err => {
        setErrorMsg(err.response.data.message);
        setLoader(false);
      })
    }
    return (
      <>
        {loader && <Loader />}
        <form onSubmit={handleSubmit(onSubmit)}>
          <Row>
            <Col sm="12">
              <div className="mb-3">
                <Label htmlFor="productname">Mobile Number</Label>
                <Controller
                  as={<CustomInput />}
                  defaultValue={null}
                  name="mobile"
                  type="tel"
                  onChange={([e]) => {
                    return { value: e };
                  }}
                  control={control}
                  rules={{ required: true, validate: validatePhone }}
                />
                {errors.mobile &&
                  errors.mobile.type &&
                  errors.mobile.type == "required"
                  ?
                  <span style={{ color: "red" }}>number is required</span> : ""}
                {errors.mobile &&
                  errors.mobile.type &&
                  errors.mobile.type == "validate"
                  ?
                  <span style={{ color: "red" }}>Maximum ten digits are allowed</span> : ""}
                {errorMsg?
                  <span style={{ color: "red" }}>{errorMsg}</span> : ""}
              </div>
            </Col>
            <Col sm="12">
              <div className="d-flex flex-wrap gap-2">
                <Button
                  type="submit"
                  color="primary"
                  className="btn "
                >
                  Generate OTP
                </Button>
              </div>
            </Col>
          </Row>
        </form>
      </>
    )
  }

  const ValidateOtp = () => {
    const { handleSubmit, errors, control } = useForm({
      mode: 'onChange'
    });
    const [loader, setLoader] = useState(false);
    const [errorMsg, setErrorMsg] = useState(null);
    const onSubmit = (values) => {
      values.mobile = mobileNumber;
      setLoader(true);
      post("/api/v1/user/mobile_verified/", values).then(res => {
        setLoader(false);
        setErrorMsg(null);
        setType("register");
      }).catch(err => {
        setErrorMsg(err.response.data.message);
        setLoader(false);
      })
    }
    return (
      <>
        {loader && <Loader />}
        <form onSubmit={handleSubmit(onSubmit)}>
          <Row>
            <Col sm="12">
              <div className="mb-3">
                <h5 htmlFor="productname" style={{textAlign:"center"}}>{mobileNumber}</h5>
                <h6 onClick={()=> setType("generateOtp")} style={{marginLeft: "15px",
                                                              textAlign: "right",
                                                              textDecorationLine: "underline",
                                                              color: "red",
                                                              cursor:"pointer"}}>Edit Mobile Number</h6>
                <Label htmlFor="productname">Enter OTP</Label>
                <Controller
                  as={<CustomInput />}
                  defaultValue={null}
                  name="otp"
                  type="number"
                  onChange={([e]) => {
                    return { value: e };
                  }}
                  control={control}
                  rules={{ required: true }}
                />
                {errors.otp &&
                  errors.otp.type &&
                  errors.otp.type == "required"
                  ?
                  <span style={{ color: "red" }}>otp is required</span> : ""}
                {errorMsg?
                  <span style={{ color: "red" }}>{errorMsg}</span> : ""}
              </div>
            </Col>
            <Col sm="12">
              <div className="d-flex flex-wrap gap-2">
                <Button
                  type="submit"
                  color="primary"
                  className="btn "
                >
                  Validate OTP
                </Button>
              </div>
            </Col>
          </Row>
        </form>
      </>
    )
  }

  const Register = () => {
    const { handleSubmit, errors, control } = useForm({
      mode: 'onChange'
    });
    const [loader, setLoader] = useState(false);
    const [errorMsg, setErrorMsg] = useState(null);
    const onSubmit = (values) => {
      values.mobile = mobileNumber;
      values.confirm_password = values.password;
      values.type="Seller";
      setLoader(true);
      post("/api/v1/user/register/", values).then(res => {
        setLoader(false);
        setErrorMsg(null);
        history.push(`/add-company/${res.user_id}`)
      }).catch(err => {
        setErrorMsg(err.response.data.message);
        setLoader(false);
      })
    }
    return (
      <>
        {loader && <Loader />}
        <form onSubmit={handleSubmit(onSubmit)}>
          <Row>
            <Col sm="12">
              <div className="mb-3">
                <h5 htmlFor="productname" style={{textAlign:"center"}}>{mobileNumber}</h5>
                <Label htmlFor="productname">Email</Label>
                <Controller
                  as={<CustomInput />}
                  defaultValue={null}
                  name="email"
                  type="email"
                  onChange={([e]) => {
                    return { value: e };
                  }}
                  control={control}
                  rules={{ required: true }}
                />
                {errors.email &&
                  errors.email.type &&
                  errors.email.type == "required"
                  ?
                  <span style={{ color: "red" }}>Email is required</span> : ""}
              </div>
            </Col>
            <Col sm="12">
              <div className="mb-3">
                <Label htmlFor="productname">Full Name</Label>
                <Controller
                  as={<CustomInput />}
                  defaultValue={null}
                  name="name"
                  type="text"
                  onChange={([e]) => {
                    return { value: e };
                  }}
                  control={control}
                  rules={{ required: true }}
                />
                {errors.name &&
                  errors.name.type &&
                  errors.name.type == "required"
                  ?
                  <span style={{ color: "red" }}>Name is required</span> : ""}
              </div>
            </Col>
            <Col sm="12">
              <div className="mb-3">
                <Label htmlFor="productname">Set Password</Label>
                <Controller
                  as={<CustomInput />}
                  defaultValue={null}
                  name="password"
                  type="password"
                  onChange={([e]) => {
                    return { value: e };
                  }}
                  control={control}
                  rules={{ required: true }}
                />
                {errors.password &&
                  errors.password.type &&
                  errors.password.type == "required"
                  ?
                  <span style={{ color: "red" }}>Password is required</span> : ""}
                {errorMsg?
                  <span style={{ color: "red" }}>{errorMsg}</span> : ""}
              </div>
            </Col>
            <Col sm="12">
              <div className="d-flex flex-wrap gap-2">
                <Button
                  type="submit"
                  color="primary"
                  className="btn "
                >
                  Register
                </Button>
              </div>
            </Col>
          </Row>
        </form>
      </>
    )
  }

  return (
    <React.Fragment>
      <MetaTags>
        <title>Register |  BrakeUp</title>
      </MetaTags>
      <div className="home-btn d-none d-sm-block">
        <Link to="/" className="text-dark">
          <i className="fas fa-home h2" />
        </Link>
      </div>
      <div className="account-pages my-5 pt-sm-5">
        <Container>

          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="overflow-hidden">
                <div className="" style={{ backgroundColor: "rgb(254 224 52)" }}>
                  <Row>
                    <Col xs={7}>
                      <div className="p-4" style={{ color: "black" }}>
                        <h5 >Welcome Back !</h5>
                        <p>Sign up to continue to Brakeup.</p>
                      </div>
                    </Col>
                    <Col className="col-5 align-self-end">
                      <img src={logo} alt="" className="img-fluid" />
                    </Col>
                  </Row>
                </div>
                <CardBody className="pt-0">
                  <div className="p-2">
                    {(() => {
                      switch (type) {
                        case "generateOtp":
                          return (<GenerateOtp />)
                        case "validateOtp":
                          return (<ValidateOtp />)
                        case "register":
                          return (<Register />)
                        default:
                          return (<GenerateOtp />)
                      }
                    })()}
                  </div>
                </CardBody>
              </Card>
              <div className="mt-5 text-center">
                <p>
                  Already have an account ?{" "}
                  <Link to="/login" className="font-weight-medium text-primary">
                    {" "}
                    Login
                  </Link>{" "}
                </p>
                <p>
                  © {new Date().getFullYear()} Brakeup.
                  {/* Crafted with{" "}
                  <i className="mdi mdi-heart text-danger" /> by Themesbrand */}
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default Register
