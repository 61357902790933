import React, { useState, useEffect } from "react"
import {
  Col,
  Button,
  Row,
  Table,
} from "reactstrap";
import { useHistory } from "react-router-dom";
import logo from "assets/images/logo.png"
import { AiFillEdit } from "react-icons/ai";
import { get } from "../../helpers/api_helper";
import { Loader } from "../../service/alerts";

const MyBusiness = props => {
  const [firstForm, setFirstForm] = useState({});
  const [secondForm, setSecondForm] = useState({});
  const [loader, setLoader] = useState(false);
  const history = useHistory();
  const id = history.location.pathname.split("/").reverse()[0];
  useEffect(() => {
    setLoader(true);
    const id = history.location.pathname.split("/").reverse()[0];
    get(`/api/v1/seller/sellerprofile/?user_id=${id}`).then(res => {
      let firstForm = {
        authorized_person: res.authorized_person,
        company_logo: res.company_logo,
        pickup_location: res.pickuplocation,
        company_name: res.company_name,
        display_name: res.display_name,
        pickup_address: res.pickupaddress,
        reg_address: res.reg_address,
        user_name: res.user_name,
        mobile: res.user_detail[0][0].mobile,
        email:res.user_detail[0][0].email,
      }
      let secondForm = {
        GST_type: res.GST_type,
        PAN: res.PAN,
        bank_IFSC: res.bank_IFSC,
        bank_accno: res.bank_accno,
        bank_branch: res.bank_branch,
        bank_name: { label: res.bank_name, value: res.bank_name },
        GST_number: res.GST_number

      }
      setFirstForm(firstForm);
      setSecondForm(secondForm);
      setLoader(false);
    }).catch(err => {
      setLoader(false);
    })
  }, [])
  return (
    <React.Fragment>
      <div className="" style={{ backgroundColor: "rgb(254 224 52)", height: "81px" }}>
        <Row>
          <Col xs={7}>
            <div className="p-4" style={{ color: "black" }}>
              <h5 >Welcome Back !</h5>
              <p></p>
            </div>
          </Col>
          <Col className="col-5 " style={{ textAlign: "right" }}>
            <img src={logo} alt="" style={{ height: "80px" }} />
          </Col>
        </Row>
      </div>
      {loader && <Loader />}
      <Row>
        <Col lg={12} style={{ textAlign: "right", marginTop: "15px", paddingRight: "30px" }}>
          <Button color="primary" onClick={(d) => history.push(`/add-company/${id}`)}>Edit <AiFillEdit/></Button>
        </Col>
      </Row>
      <Row style={{ textAlign: "center" }}>
        <Col lg={3}>
          <div className="row mb-4">
            <h5
            >
              COMPANY NAME
            </h5>
            <Col lg={12}>
              <h5>{firstForm.company_name}</h5>
            </Col>
          </div>
          <div className="row mb-4">
            <h5>
              DISPLAY NAME
            </h5>
            <Col lg={12}>
              <h5>{firstForm.display_name}</h5>
            </Col>
          </div>
          <div className="row mb-4">
            <h5>
              AUTHORIZED PERSON
            </h5>
            <Col lg={12}>
              <h5>{firstForm.authorized_person}</h5>
            </Col>
          </div>
          <div className="row mb-4">
            <h5>
              REGISTERED ADDRESS
            </h5>
            <Col lg={12}>
              <h5>{firstForm.reg_address}</h5>
            </Col>
          </div>
          <div className="row mb-4">
            <h5>
              MOBILE
            </h5>
            <Col lg={12}>
              <h5>{firstForm.mobile}</h5>
            </Col>
          </div>
          <div className="row mb-4">
            <h5>
              EMAIL
            </h5>
            <Col lg={12}>
              <h5>{firstForm.email}</h5>
            </Col>
          </div>
        </Col>
        <Col lg={3}>
          <div className="row mb-4">
            <h5
            >
              PAN NUMBER
            </h5>
            <Col lg={12}>
              <h5>{secondForm.PAN}</h5>
            </Col>
          </div>
          <div className="row mb-4">
            <h5
            >
              GST DETAILS
            </h5>
            <Col lg={12}>
              <h5>{secondForm.GST_number}</h5>
            </Col>
          </div>

          <div className="row mb-4">
            <h5
            >
              GST TYPE
            </h5>
            <Col lg={12}>
              <h5>{secondForm.GST_type}</h5>
            </Col>
          </div>

          <div className="row mb-4">
            <h5
            >
              BANK ACCOUNT NUMBER
            </h5>
            <Col lg={12}>
              <h5>{secondForm.bank_accno}</h5>
            </Col>
          </div>

          <div className="row mb-4">
            <h5
            >
              BANK NAME
            </h5>
            <Col lg={12}>
              <h5>{secondForm.bank_name?.label}</h5>
            </Col>
          </div>

          <div className="row mb-4">
            <h5
            >
              BRANCH NAME
            </h5>
            <Col lg={12}>
              <h5>{secondForm.bank_branch}</h5>
            </Col>
          </div>

          <div className="row mb-4">
            <h5
            >
              IFSC
            </h5>
            <Col lg={12}>
              <h5>{secondForm.bank_IFSC}</h5>
            </Col>
          </div>
        </Col>
        <Col lg={6}>
          <div className="row mb-4" style={{ width: "250px" }}>
            <img
              data-dz-thumbnail=""
              height="80"
              className="avatar-sm rounded bg-light"
              style={{ width: "100%", height: "100%" }}
              alt={"image"}
              src={firstForm.company_logo}
            />
          </div>
          <div className="mt-5">
            <div className="table-responsive">
              <Table className="table mb-0 table-bordered">
                <tbody>
                  <tr key="header">
                    <th

                    >
                      Pickup location
                    </th>
                    <th>Address 1</th>
                    <th>Address 2</th>
                    <th>City</th>
                    <th>State</th>
                    <th>Country</th>
                    <th>Pin Code</th>
                  </tr>
                  {firstForm.pickup_location &&
                    firstForm.pickup_location.map((items, i) => (
                      <tr key={i}>
                        <td>
                          {items.pickup_location}
                        </td>
                        <td>{items.address}</td>
                        <td>{items.address_2}</td>
                        <td>{items.city}</td>
                        <td>{items.state}</td>
                        <td>{items.country}</td>
                        <td>{items.pincode}</td>
                      </tr>
                    ))}
                </tbody>
              </Table>
            </div>
          </div>
        </Col>
      </Row>
    </React.Fragment>
  )
}

export default MyBusiness
