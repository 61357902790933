import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
import {
  Container,
  Row,
  Col,
  Button,
  Card,
  CardBody,
  CardSubtitle,
  Input,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Media,
  Table,
  CardTitle,
  CardText,
  Badge,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
} from "reactstrap"

import Brakeuplogo from "../../assets/images/logo.png"
import Pebbleslogo from "../../assets/images/Pebbles Digital Logo.jpg"

import Trycyferlogo from "../../assets/images/Trycyfer-white.png"
import Trycyferlogo2 from "../../assets/images/try2.jpeg"
import Brintlogo from "../../assets/images/Brint Logo.png"

import moment from "moment"

//i18n
import { withTranslation } from "react-i18next"

//redux
import { get, post } from "../../helpers/api_helper"
import { error } from "../../service/alerts"

import "react-date-range/dist/styles.css" // main style file
import "react-date-range/dist/theme/default.css" // theme css file
import { DateRange } from "react-date-range"
import { Link } from "react-router-dom"

import NotificationDropdown from "../../components/CommonForBoth/TopbarDropdown/NotificationDropdown"
import BootstrapTable from "react-bootstrap-table-next"
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css"

import { Spin } from "antd"

export const Loader2 = () => {
  return (
    <div
      style={{
        position: "absolute",
        width: "100%",
        height: "100%",
      }}
    >
      <Spin
        tip="Loading..."
        className="loading"
        style={{
          display: "block",
          position: "fixed",
          zIndex: 6,
          margin: "auto",
          top: "40%",
        }}
      />
    </div>
  )
}

const MarketingUI = props => {
  const [orderData, setOrderData] = useState([])
  const [despatchAmount, setDespatchAmount] = useState(0)
  const [totalOrders, setTotalOrders] = useState(0)

  const [menu, setMenu] = useState(false)

  var startDate = new Date(new Date().setDate(new Date().getDate() - 5))
  var endDate = new Date()

  const [state, setState] = useState([
    {
      startDate: startDate,
      endDate: endDate,
      key: "selection",
    },
  ])

  const [loader, setloader] = useState(false)

  const getData = (date_from, date_to) => {
    setloader(true)
    get(
      `/api/v1/order/guests_user/order/?datefrom=${date_from}&dateto=${date_to}`
    )
      .then(res => {
        let order_list = []
        res?.order_list &&
          res?.order_list.map((items, index) => {
            order_list.push({
              no: index + 1,
              order_id: items.order_id,

              date: items.created_at["$date"],
              quantity: items.total_items,
              order_value: items.order_total,
              buyer_name: items.billing_customer_name,
              buyer_Location: `${items.billing_city}, ${items.billing_state}`,
              vehicle: items.product_details,
            })
          })
        setOrderData(order_list)
        setloader(false)
      })
      .catch(err => {
        setOrderData([])
        setloader(false)
      })
  }

  const DownloadData = (date_from, date_to) => {
    setloader(true)
    const filename = `ORDERLIST ${date_from}/${date_to}.xlsx`

    post("/api/v1/order/excel_download/ ", {
      type: "orders",
      datefrom: date_from,
      dateto: date_to,
    })
      .then(res => {
        setloader(false)

        fetch(res?.url, {
          method: "GET",
          headers: {
            "Content-Type": "blob",
          },
          responseType: "arraybuffer",
        })
          .then(response => response.blob())
          .then(blob => {
            console.log(blob, "blob")
            // Create blob link to download
            const url = window.URL.createObjectURL(new Blob([blob]))
            const link = document.createElement("a")
            link.href = url
            link.setAttribute("download", filename)
            // Append to html link element page
            document.body.appendChild(link)

            // Start download
            link.click()

            // Clean up and remove the link
            link.parentNode.removeChild(link)
          })
      })
      .catch(err => {
        setloader(false)
        error("Not able to download orderlist")
      })
  }
  useEffect(() => {
    getData(
      moment(startDate).format("YYYY-MM-DD"),
      moment(endDate).format("YYYY-MM-DD")
    )
  }, [])

  const columns = [
    {
      dataField: "no",
      text: "Sl No",
      align: "center",
      sort: true,
      width: "col col-lg-2",
      headerAlign: "center",
      headerStyle: () => {
        return { width: "70px" }
      },
    },
    {
      dataField: "order_id",
      text: "Order No",
      sort: true,
      align: "center",
      headerAlign: "center",
      headerStyle: () => {
        return { width: "100px" }
      },
    },
    {
      dataField: "date",
      text: "Date",
      sort: true,
      align: "center",
      headerAlign: "center",
      headerStyle: () => {
        return { width: "110px" }
      },
      formatter: cell => {
        return (
          <>
            <div className="rowsOrderMrkt">
              {moment(cell).format("DD-MM-YYYY")}
            </div>
            <div className="rowsOrderMrkt">
              {moment(cell).format("hh:mm A")}
            </div>
          </>
        )
      },
    },
    {
      dataField: "quantity",
      text: "Quantity",
      align: "center",
      headerAlign: "center",
      headerStyle: () => {
        return { width: "80px" }
      },
    },
    {
      dataField: "order_value",
      text: "Order Total",
      sort: true,
      align: "center",
      headerAlign: "center",
      headerStyle: () => {
        return { width: "110px" }
      },
    },
    {
      dataField: "buyer_name",
      text: "Buyer",
      align: "center",
      headerAlign: "center",
      formatter: cell => {
        return (
          <div className="rowsOrderMrkt">
            <p className="mb-0">{cell}</p>
          </div>
        )
      },
    },
    {
      dataField: "buyer_Location",
      text: "Buyer Location",
      align: "center",
      headerAlign: "center",
      headerStyle: () => {
        return { overflow: "hidden" }
      },
      formatter: cell => {
        return (
          <div className="rowsOrderMrkt">
            <p className="mb-0">{cell}</p>
          </div>
        )
      },
    },
    {
      dataField: "vehicle",
      text: "Vehicle",
      sort: true,
      align: "center",
      headerAlign: "center",
      headerStyle: () => {
        return { overflow: "hidden" }
      },
      formatter: cell => {
        return (
          <>
            {cell.map(data => (
              <div className="rowsOrderMrkt">
                <p className="mb-0">
                  {data.make} {data.model} {data.year}
                </p>
              </div>
            ))}
          </>
        )
      },
    },
    {
      dataField: "vehicle",
      text: "Brand",
      sort: true,
      align: "center",
      headerAlign: "center",
      style: { position: "relative" },
      headerStyle: () => {
        return { width: "7rem" }
      },
      formatter: cell => {
        return (
          <>
            {cell.map(data => (
              <div className="rowsOrderMrkt">
                <p className="mb-0">{data.brand}</p>
              </div>
            ))}
          </>
        )
      },
    },
    {
      dataField: "vehicle",
      text: "Order Status",
      align: "center",
      headerAlign: "center",
      formatter: cell => {
        return (
          <>
            {cell.map(data => (
              <div className="rowsOrderMrkt">
                <p className="mb-0">{data.order_status}</p>
              </div>
            ))}
          </>
        )
      },
    },
  ]

  const defaultSorted = [
    {
      dataField: "id",
      order: "asc",
    },
  ]

  return (
    <React.Fragment>
      {loader && <Loader2 />}

      <div>
        <header id="page-topbar" style={{ left: 0 }}>
          <div className="navbar-header">
            <Link
              to={{ pathname: "https://brakeup.in/" }}
              target="_blank"
              className="ms-5"
              data-bs-toggle="tooltip"
              title="https://brakeup.in/"
            >
              <img src={Brakeuplogo} alt="" width="80" />
            </Link>
            <div className="d-flex">
              {/* <NotificationDropdown /> */}

              <Dropdown
                isOpen={menu}
                toggle={() => setMenu(!menu)}
                className="d-inline-block"
              >
                <DropdownToggle
                  className="btn header-item "
                  id="page-header-user-dropdown"
                  tag="button"
                >
                  <img
                    style={{
                      height: "45px",
                      width: "45px",
                      objectFit: "contain",
                    }}
                    className="rounded-circle bg-dark"
                    src={Brintlogo}
                    alt="Header Avatar"
                  />
                  <span className="d-xl-inline-block ms-2 me-1">
                    Brint Marketing
                  </span>
                  <i className="mdi mdi-chevron-down d-none d-xl-inline-block" />
                </DropdownToggle>
                <DropdownMenu className="dropdown-menu-end">
                  <div className="dropdown-divider" />
                  <Link to="/logout" className="dropdown-item">
                    <i className="bx bx-power-off font-size-16 align-middle me-1 text-danger" />
                    <span>{props.t("Logout")}</span>
                  </Link>
                </DropdownMenu>
              </Dropdown>
            </div>
          </div>
        </header>
      </div>
      <div className="m-2 mt-5 m-sm-5 pt-5">
        <MetaTags>
          <title>Orders List | Brakeup</title>
        </MetaTags>
        <Container fluid>
          <Row>
            <Col xs="12">
              <Col className="page-title-box d-sm-flex align-items-center justify-content-between">
                <h4 className="mb-0 font-size-18">Orders List</h4>
              </Col>
            </Col>
          </Row>

          <Row className="mb-4">
            <Col lg="5">
              <DateRange
                editableDateInputs={true}
                onChange={item => {
                  setState([item.selection])
                  getData(
                    moment(item.selection.startDate).format("YYYY-MM-DD"),
                    moment(item.selection.endDate).format("YYYY-MM-DD")
                  )
                }}
                moveRangeOnFirstSelection={false}
                ranges={state}
                showDateDisplay={true}
              />
            </Col>
            <Col className="d-flex align-items-end text-nowrap">
              <Row className="align-items-center">
                <Col className="">
                  <button
                    type="button"
                    class="btn btn-info text-uppercase"
                    onClick={() => {
                      DownloadData(
                        moment(state[0]?.startDate).format("YYYY-MM-DD"),
                        moment(state[0]?.endDate).format("YYYY-MM-DD")
                      )
                    }}
                  >
                    Download Order List
                  </button>
                </Col>
                <Col>
                  <div className="text-nowrap text-center ">
                    from{" "}
                    <span className="fw-bold">
                      {moment(state[0].startDate).format("DD-MM-YYYY")}
                    </span>
                    <span className="mx-2">to</span>
                    <span className="fw-bold">
                      {moment(state[0].endDate).format("DD-MM-YYYY")}
                    </span>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>

          <Row>
            <Col>
              <Card>
                <div className="table-responsive">
                  <BootstrapTable
                    keyField={"id"}
                    responsive
                    bordered={false}
                    striped={false}
                    defaultSorted={defaultSorted}
                    tabIndexCell
                    // rowEvents={ rowEvents }
                    // selectRow={selectRow}
                    classes={"table2 align-middle table-nowrap"}
                    headerWrapperClasses={"thead-light"}
                    // {...toolkitProps.baseProps}
                    // {...paginationTableProps}
                    data={orderData}
                    columns={columns}
                  />
                </div>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
      <footer className="footer2">
        <Container fluid={true}>
          <Row>
            <Col md={6}>{new Date().getFullYear()} © Brakeup</Col>
            <Col md={6}>
              <div className="text-sm-end d-none d-sm-block">
                Design & Developed by <span className="fw-bold">Brakeup</span>
              </div>
            </Col>
          </Row>
        </Container>
      </footer>
    </React.Fragment>
  )
}

export default withTranslation()(MarketingUI)
