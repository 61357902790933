import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
import PropTypes from "prop-types"
import { Link } from "react-router-dom"
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  Table,
  TabPane,
  Input
} from "reactstrap"
import classnames from "classnames"
import { isEmpty } from "lodash"
import Select from "react-select";


//Import Star Ratings
import StarRatings from "react-star-ratings"

//Import Product Images

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb"

//Import actions

import { get, post } from "../../helpers/api_helper";
import { success, error, Loader } from "../../service/alerts";

//redux
import { useDispatch, useStore } from "react-redux";
import { useHistory } from "react-router-dom";
import Img from "../../assets/images/not-approved.png"


const SellerProductDetail = props => {
  const dispatch = useDispatch()
  const [product, setProduct] = useState({});
  const [orders, setOrders] = useState({});
  const [sellerDetails, setsellerDetails] = useState({});
  const [loader, setLoader] = useState(false);
  const [approvedProducts, setApprovedProducts] = useState([]);
  const [activedProducts, setActivedProducts] = useState([]);
  const [search, setSearch] = useState("");
  const [filter, setFilter] = useState([]);
  const history = useHistory();
  const {
    match: { params },
  } = props
  const [activeTab, setActiveTab] = useState(0);
  const getProductDetails = async () => {
    const token = JSON.parse(localStorage.getItem("authUser"));
    get(`/api/v1/products/vendorproducts/?user_id=${token.id}`).then(res => {
      setApprovedProducts(res);
    }).catch(err => {

    })
  }
  useEffect(() => {
    if (params) {
      getProductDetails(1)
    }
  }, [params])
  const onSearchChange = async (searchValue, filterValue) => {
    const token = JSON.parse(localStorage.getItem("authUser"));
    let url = "/api/v1/search/?q=vendor_products&users_id=" + token.id;
    if (searchValue) {
      url = `${url}&s=${searchValue}`
    }
    if (filterValue) {
      let filter = ""
      filterValue && filterValue.map(items => {
        if (items.value == "all") {
          filter = `${filter}&all=True`
        }
        if (items.value == "0") {
          filter = `${filter}&stock=0`
        }
        if (items.value == "in_active") {
          filter = `${filter}&inactive=False`
        }
        if (items.value == "active") {
          filter = `${filter}&active=True`
        }
        if (items.value == "low_stock") {
          filter = `${filter}&total_stock=5`
        }
        if (items.value == "new_product") {
          filter = `${filter}&new_products=new`
        }
        if (items.value == "pending_approval") {
          filter = `${filter}&isApproved=False`
        }
        if(items.value == "Instockproducts"){
          filter = `${filter}&instock=1`
        }
        
      })
      url = `${url}${filter}`
    }
    setSearch(searchValue);
    setFilter(filterValue);

    get(url).then(res=>{
      setApprovedProducts(res);
    }).catch(err=>{
      setApprovedProducts([]);
    })
    get(url).then(res=>{
      setActivedProducts(res);
    }).catch(err=>{
      setActivedProducts([]);
    })
    
  }
  const cardsGenerate = (product, key) => {
    return (<Col xl="4" sm="4" key={"_col_" + key}>
      <Card
        onClick={() =>
          history.push(
            `/ecommerce-product-detail/${product.prod_id}`
          )
        }
      >
        <CardBody style={{height:"730px"}}>
          <div className="product-img position-relative">
            {product.isApproved == false?
            <img src={Img} style={{height: "50px", width:"260px"}} alt=""/>:""}
            <br/>
            <span className="text-muted me-2">
              Product Name
            </span>
            <h5 className="mb-3 text-truncate">{product.prod_name}</h5>
            {product.image &&
              product.image[0] && <img
                src={product.image[0].startsWith("https://") ? product.image[0] : `https://${product.image[0]}`}
                alt=""
                className="img-fluid mx-auto d-block"
                style={{width:"200px", height:"200px"}}
              />}
          </div>

          <div className="mt-4 text-center">
            <span className="text-muted me-2">
              Product Category
            </span>
            <h5 className="mb-3 text-truncate">{product.category}</h5>
            <span className="text-muted me-2">
              Seller Part Number
            </span>
            <h5 className="mb-3 text-truncate">{product.seller_part_no}</h5>
            <span className="text-muted me-2">
              Brand
            </span>
            <h5 className="mb-3 text-truncate">{product.brand}</h5>
            <span className="text-muted me-2">
              Type
            </span>
            <h5 className="mb-3 text-truncate">{product.type}</h5>
            <span className="text-muted me-2">
              Stock
            </span>
            <h5 className="mb-3 text-truncate">{product.stock}</h5>
            <span className="text-muted me-2">
              Selling price
            </span>
            <h5 className="mb-3 text-truncate">{product.selling_price}</h5>
          </div>
        </CardBody>
      </Card>
    </Col>
    )
  }
  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>
            Seller Detail |  BrakeUp
          </title>
        </MetaTags>
        <Container fluid>
          {loader && <Loader />}
          <Breadcrumbs title="Commercial" breadcrumbItem="Seller Detail" />
          <Row>
            <Col style={{ textAlign: "right" }}>
              <Button color="primary" onClick={() => history.push("/products/")}>Add Products</Button>
            </Col>
          </Row>
          <Row style={{marginTop:"20px"}}>
            <Col xl="8" sm="6">
              <div className="search-box me-2">
                <div className="position-relative">
                  <Input
                    type="text"
                    className="form-control border-0"
                    placeholder="Search..."
                    onChange={(e) => onSearchChange(e.target.value, filter)}
                  />
                  <i className="bx bx-search-alt search-icon" />
                </div>
              </div>
            </Col>
            <Col lg="4" sm="6">
              <div style={{ width: "300px" }}>
                <Select
                  style={{ width: "200px" }}
                  classNamePrefix="select"
                  name="filers"
                  placeholder="Filters"
                  isMulti
                  options={[{ label: "All Products", value: "all" },
                  { label: "Zero Stock Products", value: "0" },
                  { label: "Low Stock", value: "low_stock" },
                  { label: "in Active Products", value: "in_active" },
                  { label: "Active Products", value: "active" },
                  { label: "New Product Within Month", value: "new_product" },
                  { label: "Frequently Ordered", value: "frequently_ordered" },
                  { label: "Pending Approval", value: "pending_approval" },
                  {label: "In stock products", value:"Instockproducts"},


                  ]}
                  onChange={e => {
                    onSearchChange(search, e)
                  }}
                />
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <Card>
                <CardBody>
                  <Row>
                    <Col xl="12">

                      <Row>
                        {!isEmpty(approvedProducts) &&
                          approvedProducts.map((product, key) => (
                            cardsGenerate(product, key)
                          ))}
                      </Row>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>

        </Container>
      </div>
    </React.Fragment>
  )
}

SellerProductDetail.propTypes = {
  product: PropTypes.object,
  onGetProductDetail: PropTypes.func,
}

export default SellerProductDetail
