import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
import {
  Container,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  Badge,
  DropdownItem,
  Row,
  Col,
  Table

} from "reactstrap"
import companies from "assets/images/companies"

import {Link} from 'react-router-dom';
import moment from 'moment';

//import action
import { getChartsData as onGetChartsData } from "../../store/actions"
import TableRow from './buyerRow'
import {useHistory }  from 'react-router-dom'



//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"

//i18n
import { withTranslation } from "react-i18next"
import { get } from "../../helpers/api_helper";

//redux
import { useSelector, useDispatch } from "react-redux"
import PerfectScrollbar from "react-perfect-scrollbar"
import "react-perfect-scrollbar/dist/css/styles.css"



const Buyer = props => {
  const [buyers, setBuyers] = useState([])
  const [limit, setLimit] = useState(4)
  const [last_id, setLastId] = useState("")

  const history = useHistory()

  const getBuyers = async()=>{
    get(`/api/v1/order/orderdetails/?limit=${limit}`)
    .then(response => {
      setBuyers(response.data)
    })
    .catch(error => {
  
    })
  }


  useEffect(() => {
    getBuyers()
  }, [])



  
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(onGetChartsData("yearly"))
  }, [dispatch]);

  const onNext = () => {
    
    let last_item = buyers[buyers.length-1]
    console.log(last_item,last_id, "lasttttttt")
    if(last_item && last_id!=last_item._id.$oid){
      get(`/api/v1/order/orderdetails/?limit=${limit}&previousId=${last_item._id.$oid}`)
      .then(response => {
        if(response.data){
          let new_buyers = [
            ...buyers,
            ...response.data
          ]
          setBuyers(new_buyers)
        }
        
      })
      .catch(error => {
    
      })
      setLastId(last_item._id.$oid)
    }
  }

  const onClickRow = (item) => {
    history.push(`/buyers/${item.user_id.$oid}`)
    // history.push('/buyers/12')
}
  return (
    <React.Fragment>
      <div className="page-content" style={{background: "white"}}>
        <MetaTags>
          <title>Buyers | BrakeUp</title>
        </MetaTags>
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs
            title={props.t("Buyers")}
            breadcrumbItem={props.t("Buyers")}
          />

        <Row>
          <PerfectScrollbar onYReachEnd={() => onNext()} style={{ height: "400px" }}>
            <Col lg="12">
              <div className="">
                <div className="table-responsive">
                  <Table className="project-list-table table-nowrap align-middle  table">
                    <tbody>
                    {
                        buyers.map((item, index) => (
                          <>
                          <TableRow
                          {...item}
                            onClick={()=>onClickRow(item)}
                            color={item.is_Approved ? "green" : 'red'}
                            />
                            <br/>
                          </>
                        ))
                      }

                        </tbody>
                  </Table>
                </div>
              </div>
            </Col>
            </PerfectScrollbar>
          </Row>


        </Container>
      </div>

 
    </React.Fragment>
  )
}

Buyer.propTypes = {
  t: PropTypes.any,
  chartsData: PropTypes.any,
  onGetChartsData: PropTypes.func,
}

export default withTranslation()(Buyer)
