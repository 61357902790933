import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
import {
  Container,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  Badge,
  DropdownItem,
  Row,
  Col,
  Table,
  Card,
  CardBody

} from "reactstrap"
// import companies from "assets/images/users"

import { Link } from 'react-router-dom';

import avatar from "assets/images/users/avatar-1.jpg"
import avatar1 from "assets/images/users/avatar-2.jpg"
import avatar2 from "assets/images/users/avatar-3.jpg"

//import action

import PerfectScrollbar from "react-perfect-scrollbar"
import "react-perfect-scrollbar/dist/css/styles.css"



//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"

//i18n
import { withTranslation } from "react-i18next"
import { get } from "../../helpers/api_helper";
import axios from "axios";
import BuyerRowDetail from './buyerDetailRow';
//redux

const BuyerDetail = props => {

  const [orders, setOrders] = useState([])
  const [user, setUser] = useState('')


  const getUser = (id) => get(`/api/v1/user/user_details/?user_id=${id}`)
    const getOrder = (id) => get(`/api/v1/order/orderbyusers/?user_id=${id}`)


useEffect(() => {
  let user_id = window.location.pathname.split('/').reverse()[0]
  axios.all([
    getUser(user_id),
    getOrder(user_id)
  ]).then(axios.spread((user, order) => {
    console.log(user, order, 'blaaaaa')
    setUser(user)
    setOrders(order.data.data)
  }))
  // get(`/api/v1/order/orderbyusers/?user_id=${user_id}`)
  // .then(response => {
  //   console.log(response.data.data)
  //   setOrders(response.data.data)
  //   // console.log(moment(response.data[0]['created_at']['$date']).format('LLLL'))
  // })
  // .catch(error => {

  // })


})







  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Buyer Detail | BrakeUp</title>
        </MetaTags>
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs
            title={props.t("Buyer Detail")}
            breadcrumbItem={props.t("Buyer Detail")}
          />
          <br />

          <Row>



            <Col md={3}>
              <Card style={{height:"100%"}}>
                <CardBody>
                  {
                    user && 
                    <>
                    <div className="text-muted mb-1 p-2 bordered-div" >
                      {user.buyer_name}
                    </div>
                    <div className="text-muted mb-1 p-2 bordered-div" >
                    {user.buyer_mobile}
                    </div>
                    <div className="text-muted mb-1 p-2 bordered-div" >
                    {user.buyer_email}
                    </div>
                    <div className="text-muted mb-1 p-2 bordered-div" >
                    {user.buyer_address}
                    </div>
                    </>
                  }

                </CardBody>
              </Card>
            </Col>

            <Col md={9}>
            <Col md={12}>
              <Card>
                <CardBody>
                <div className='margin-left-30'>
                <h4 className="text-muted mb-0">Order History</h4>
                {/* <div className="text-muted mb-1  bordered-div w-100 " style={{ height: 200 }}> */}
                <PerfectScrollbar  style={{ height: "200px" }}>
                <Col lg="12">
                    <div className="table-responsive">
                      <Table className="project-list-table table-nowrap align-middle  table">
                        <tbody>
                          {
                            orders.map(item => (
                              <BuyerRowDetail {...item}/>
                            ))
                          }
                        
                        </tbody>
                        </Table>
                        </div>
                        </Col>
                  
                </PerfectScrollbar>
                {/* </div> */}
              </div>
                </CardBody>
              </Card>
              
            </Col>
            <Col md={12}>
              <Card style={{marginBottom: "0px"}}>
                <CardBody>
                  <div className='margin-left-30'>
                  <h4 className="text-muted mb-0">Search History</h4>
                  <div className="text-muted mb-1 p-2 bordered-div w-100" style={{ height: 200 }}>

                  </div>
                </div>
                </CardBody>
              </Card>
              
            </Col>
                
            </Col>

          </Row>




        </Container>
      </div>


    </React.Fragment>
  )
}

BuyerDetail.propTypes = {
  t: PropTypes.any,
}

export default withTranslation()(BuyerDetail)
