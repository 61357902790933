import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import { Row, Col, Label, Button, ButtonGroup } from "reactstrap"
import { Modal, ModalBody, ModalHeader } from "reactstrap"

import moment from "moment"

//import action
import { getChartsData as onGetChartsData } from "../../store/actions"

//Import Breadcrumb

//i18n
import { withTranslation } from "react-i18next"

//redux
import { useSelector, useDispatch } from "react-redux"
import Select from "react-select"
import { success, error, options } from "toastr"
import { get, post } from "../../helpers/api_helper"
import { Loader } from "../../helpers/loader"

import Swal from "sweetalert2"
import { BiDownload } from "react-icons/bi"
import "./sweetalert.css"

const OrderRow = props => {
  // console.log(props, "orderrow");

  // const [modal, setmodal] = useState(false)
  const [subscribemodal, setSubscribemodal] = useState(false)
  const [shipDtls, setShipDtls] = useState({})
  const [confirmAmazon, setConfirmAmazon] = useState(false)
  const { chartsData } = useSelector(state => ({
    chartsData: state.Dashboard.chartsData,
  }))
  const [loader, setLoader] = useState(false)

  // useEffect(() => {
  //   setTimeout(() => {
  //     setSubscribemodal(true)
  //   }, 2000)
  // }, [])

  const [periodData, setPeriodData] = useState([])
  const [periodType, setPeriodType] = useState("yearly")

  useEffect(() => {
    setPeriodData(chartsData)
  }, [chartsData])

  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(onGetChartsData("yearly"))
  }, [dispatch])

  const handleValidDate = date => {
    const date1 = moment(new Date(date)).format("DD MMM Y")
    return date1
  }

  const handleProjectClick = arg => {
    const project = arg

    setProjectList({
      id: project.id,
      img: project.img,
      name: project.name,
      description: project.description,
      status: project.status,
      color: project.color,
      dueDate: project.dueDate,
      team: project.team,
    })

    setIsEdit(true)
    toggle()
  }

  const handleDeleteProject = project => {
    dispatch(onDeleteProject(project))
  }

  const swalWithCustomButtons1 = Swal.mixin({
    customClass: {
      confirmButton: "confirm",
      cancelButton: "warning2",
      popup: "SwalNormalContainer",
    },
    buttonsStyling: false,
  })
  const swalWithCustomButtons2 = Swal.mixin({
    customClass: {
      confirmButton: "warning",
      cancelButton: "light",
      popup: "amazonContainer",
      htmlContainer: "amazonHtmlContainer",
    },
    buttonsStyling: false,
  })

  const onSearchChange = async (searchValue, confirm_amazon) => {
    setLoader(true)
    let input = {
      order_status: searchValue,
      vendor_id: props.vendor_id,
      order_id: props.order_id,
      prod_id: props.prod_id,
    }
    let ordProid = {
      order_id: props.order_id,
      prod_id: props.prod_id,
    }

    if (searchValue == "Labeled") {
      if (confirm_amazon == true) {
        input.is_amazon = true
      } else {
        input.is_amazon = false
      }
    }
    if (searchValue == "Pickup Scheduled") {
      input.pickup_status = searchValue
    }

    post("/api/v1/seller/seller/approve_orders/", input)
      .then(res => {
        console.log(res, "approve_orders")

        if (searchValue == "Labeled" && res.confirm_amazon == true) {
          setTimeout(() => {
            swalWithCustomButtons2
              .fire({
                icon: "info",
                iconColor: "#ff0000",
                html: `<p style="padding: 0px;margin: 0px;line-height: 20px;  color:#ff0000;">AMAZON SHIPPING </p> 
            <h3 style="color:#ff0000; white-space: nowrap; " >PICK UP WILL BE AUTO ASSIGNED </h3> 
            <div style="display:flex; flex-direction:column;flex-wrap: wrap;  align-items:flex-start; align-content: center;"  >
            <p style="line-height: 20px;">Pickup will be scheduled now. Make sure package is ready to ship before you click proceed  </p> 
            <div/>`,
                confirmButtonText: "PROCEED",
                showLoaderOnConfirm: true,
                focusConfirm: false,
                reverseButtons: true,
                showCancelButton: true,
                cancelButtonText: "NEED MORE TIME",
                cancelButtonColor: "#0000",
              })
              .then(result => {
                if (result.isConfirmed) {
                  onSearchChange("Labeled", res.confirm_amazon)
                }
              })
          }, 5000)
          setTimeout(() => {
            props.getOrders()
            setLoader(false)
          }, 5000)
        } else {
          if (searchValue == "Invoiced") {
            post("/api/v1/seller/generatepdf/", ordProid)
              .then(res => {})
              .catch(err => {})
            setTimeout(() => {
              props.getOrders()
              swalWithCustomButtons1
                .fire({
                  icon: "success",
                  iconColor: "#44d836",
                  showCancelButton: true,
                  cancelButtonText: "CONTINUE",
                  cancelButtonColor: "#0000",
                  html: `<h3 style="color:#44d836; white-space: nowrap; " >${res.message} </h3> 
                        <div style="display:flex; flex-direction:column;flex-wrap: wrap;  align-items:flex-start; align-content: center;"  >
                        <p style="padding: 0px;margin: 0px;line-height: 20px;">Current Status : ${res.current_status} </p> 
                        <p style="padding: 0px;margin: 0px;line-height: 20px;">Buyer Notification : ${res.buyer_notification} </p> 
                        <p style="padding: 0px;margin: 0px;line-height: 20px;">Next Action : ${res.next_action} </p>
                        <div/>`,
                  // confirmButtonText: `<p style="text-align:left;font-size:0.9rem;margin: 0px;" ><i class='fas fa-download'></i> Download ${DowloadLabel}  </p>`,
                  focusConfirm: false,
                  showConfirmButton: false,
                })
                .then(result => {
                  if (result.isConfirmed) {
                    if (searchValue == "Invoiced") {
                      DownloadPDF("INVOICE")
                    } else {
                      // DownloadPDF("SHIPPINGLABEL")
                      window.open(props.shipping_label)
                    }
                  }
                })
            }, 5000)
            setTimeout(() => {
              props.getOrders()
              setLoader(false)
            }, 5000)
          } else if (searchValue == "Labeled") {
            setTimeout(() => {
              props.getOrders()
              swalWithCustomButtons1
                .fire({
                  icon: "success",
                  iconColor: "#44d836",
                  showCancelButton: true,
                  cancelButtonText: "CONTINUE",
                  cancelButtonColor: "#0000",
                  html: `<h3 style="color:#44d836; white-space: nowrap; " >${res.message} </h3> 
          <div style="display:flex; flex-direction:column;flex-wrap: wrap;  align-items:flex-start; align-content: center;"  >
          <p style="padding: 0px;margin: 0px;line-height: 20px;">Current Status : ${res.current_status} </p> 
          <p style="padding: 0px;margin: 0px;line-height: 20px;">Buyer Notification : ${res.buyer_notification} </p> 
          <p style="padding: 0px;margin: 0px;line-height: 20px;">Next Action : ${res.next_action} </p>
          <div/>`,
                  // confirmButtonText: `<p style="text-align:left;font-size:0.9rem;margin: 0px;" ><i class='fas fa-download'></i> Download ${DowloadLabel}  </p>`,
                  focusConfirm: false,
                  showConfirmButton: false,
                })
                .then(result => {
                  if (result.isConfirmed) {
                  }
                })
            }, 2000)

            setTimeout(() => {
              props.getOrders()
              setLoader(false)
            }, 2000)
          } else if (searchValue == "Pickup Scheduled") {
            setTimeout(() => {
              props.getOrders()
              swalWithCustomButtons1
                .fire({
                  icon: "success",
                  iconColor: "#44d836",
                  showCancelButton: true,
                  cancelButtonText: "CONTINUE",
                  cancelButtonColor: "#0000",
                  html: `<h3 style="color:#44d836; white-space: nowrap; " >${res.pickup_message} </h3>`,
                  focusConfirm: false,
                  showConfirmButton: false,
                })
                .then(result => {
                  if (result.isConfirmed) {
                    window.open(props.shipping_label)
                  }
                })
            }, 2000)

            setTimeout(() => {
              props.getOrders()
              setLoader(false)
            }, 2000)
          }
        }
      })
      .catch(err => {
        error(err?.response?.data?.message)
        setLoader(false)
        if (err?.response?.data?.message == "ORDER CANCELLED") {
          props.getOrders()
        }
      })
  }

  const DownloadPDF = type => {
    setLoader(true)
    console.log(props.invoice, props, "no props.invoice")
    if (props.invoice && !props.is_invdownload) {
      isInvShpDowloaded(type).then(() => {
        props.getOrders().then(() => {
          setTimeout(() => {
            download(type)
          }, 2000)
          // props.getOrders()
        })
      })
    } else {
      download(type)
    }
  }

  const isInvShpDowloaded = async type => {
    let data = {
      order_id: props.order_id,
      prod_id: props.prod_id,
    }
    if (type == "INVOICE") {
      data.type = "Invoice"
    } else if (type == "SHIPPINGLABEL") {
      data.type = "label"
    }

    console.log(type, data, "isInvShpDowloadedisInvShpDowloaded")

    post("/api/v1/seller/download_inv_label/", data)
      .then(res => {
        if (type == "INVOICE") {
        } else if (type == "SHIPPINGLABEL") {
          window.open(props.shipping_label)
        }
      })
      .catch(err => {})
  }

  const download = type => {
    // console.log( props.invoice ,"downloaddownloaddownload");

    // console.log(props.invoice, props.shipping_label, type, "DownloadPDF");
    let downloadurl = ""
    if (type == "INVOICE") {
      downloadurl = props.invoice
    } else if (type == "SHIPPINGLABEL") {
      downloadurl = props.shipping_label
    }

    if (downloadurl && downloadurl?.length != 0) {
      setLoader(true)

      // console.log(downloadurl, "DownloadPDFurl");

      const filename = downloadurl.substring(downloadurl.lastIndexOf("/") + 1)

      fetch(downloadurl, {
        method: "GET",
        headers: {
          "Content-Type": "application/pdf",
        },
      })
        .then(response => response.blob())
        .then(blob => {
          // Create blob link to download
          const url = window.URL.createObjectURL(new Blob([blob]))
          const link = document.createElement("a")
          link.href = url
          link.setAttribute("download", filename)
          // Append to html link element page
          document.body.appendChild(link)

          // Start download
          link.click()

          // Clean up and remove the link
          link.parentNode.removeChild(link)
        })
        .catch(err => {})
    }
    setLoader(false)
  }

  return (
    <React.Fragment>
      {loader && <Loader />}

      {/* <Modal isOpen={modal} toggle={onCloseClick} centered={true}>
        <ModalHeader toggle={onCloseClick} className="text-center">Shipment Details</ModalHeader>
        <ModalBody className="py-3 px-5">
          <Row>
            <Col>
              <div className="mt-3">
                <span>Pickup scheduled date: {shipDtls?.pickup_scheduled_date}</span><br></br>
                <span>Pickup token number: {shipDtls?.pickup_token_number}</span><br></br>
                <span>Pickup message: {shipDtls?.pickup_message}</span><br></br>
              </div>
            </Col>
          </Row>
        </ModalBody>
      </Modal> */}
      <tr
        className={`tr-border-${props.color} mb-2`}
        style={{ cursor: "default" }}
      >
        <Row className="align-items-center">
          <Col lg="3" className="d-flex flex-column">
            <h5 className="text-truncate font-size-16">
              ORDER ID : {props.order_id}
            </h5>
            <img
              src={props.image && props.image.length > 0 ? props.image[0] : ""}
              alt=""
              className="avatar-sm"
              style={{ width: "10vw", height: "auto", objectFit: "cover" }}
            />
            <Label style={{ marginTop: "20px" }} className="mb-0">
              VEHICLE DETAILS
            </Label>
            <p className="mb-0">
              {props.vehicle_details?.make} {props.vehicle_details?.model}{" "}
              {props.vehicle_details?.year} {props.vehicle_details?.version}{" "}
              {props.vehicle_details?.fueltype}
            </p>
          </Col>
          <Col lg="2">
            <Label className="mb-0">NAME</Label>
            <p className="mb-2">{props.prod_name}</p>
            <Label className="mb-0">BRAND NAME</Label>
            <p className="mb-2">{props.brand}</p>
            <Label className="mb-0">SELLER PART NUMBER</Label>
            <p className="mb-2">{props.seller_part_no}</p>
          </Col>
          <Col lg="2">
            <Label className="mb-0">PRODUCT CATEGORY</Label>
            <p className="mb-2">{props.category}</p>
            {props.type && <Label className="mb-0">PRODUCT TYPE</Label>}
            <p className="mb-2">{props.type}</p>
            <Label className="mb-0">DATE & TIME</Label>
            <p className="mb-2">
              {moment(props.created_at["$date"]).format(
                "DD-MM-YYYY, h:mm:ss a"
              )}
            </p>
            <Label className="mb-0">NUMBER OF UNITS</Label>
            <p className="mb-0">{props.quantity}</p>
          </Col>
          <Col
            lg="3"
            className="d-flex flex-column justify-content-center align-items-center"
          >
            {props.isCancelled ? (
              ""
            ) : props.isInvoiced ? (
              ""
            ) : (
              <Button
                color="success"
                className="btn "
                style={{ marginBottom: "20px" }}
                onClick={() => onSearchChange("Invoiced")}
                disabled={false}
              >
                GENERATE INVOICE
              </Button>
            )}

            {props.isLabeled ? (
              !props.isShipready && (
                <Button
                  color="success"
                  className="btn "
                  style={{ marginBottom: "20px" }}
                  onClick={() => onSearchChange("Pickup Scheduled")}
                >
                  SCHEDULE PICK UP
                </Button>
              )
            ) : props.isInvoiced ? (
              <Button
                variant="info"
                color="warning"
                className="btn "
                style={{ marginBottom: "20px" }}
                onClick={() => onSearchChange("Labeled")}
                // disabled={props.isInvoiced == true ? false : true}
              >
                GENERATE SHIP LABEL
              </Button>
            ) : (
              ""
            )}
            {/* {props.isLabeled == true &&
              props.order_status != "Pickup Scheduled" && (
                <Button
                  color="success"
                  className="btn "
                  style={{ marginBottom: "20px" }}
                  onClick={() => onSearchChange("Pickup Scheduled")}
                >
                  SCHEDULE PICK UP
                </Button>
              )} */}
            {props.isInvoiced == true && (
              <Button
                variant="primary"
                color="primary"
                className="btn "
                style={
                  props.is_invdownload
                    ? { opacity: "60%", marginBottom: "20px" }
                    : { marginBottom: "20px" }
                }
                onClick={() => {
                  DownloadPDF("INVOICE")
                }}
              >
                DOWNLOAD INVOICE
              </Button>
            )}
            {props.isLabeled == true && (
              <Button
                variant="info"
                color="info"
                className="btn "
                style={props.is_labeldownload ? { opacity: "60%" } : {}}
                onClick={() => isInvShpDowloaded("SHIPPINGLABEL")}
                // onClick={() => { DownloadPDF("SHIPPINGLABEL") }}
              >
                DOWNLOAD SHIP LABEL
              </Button>
            )}
          </Col>
          <Col lg="2">
            <Label style={{ alignSelf: "center", textAlign: "center" }}>
              STATUS
            </Label>
            <p>{props.seller_status}</p>
          </Col>
        </Row>
      </tr>
    </React.Fragment>
  )
}

OrderRow.propTypes = {
  t: PropTypes.any,
  chartsData: PropTypes.any,
  onGetChartsData: PropTypes.func,
}

export default withTranslation()(OrderRow)
